import React, { useEffect } from 'react';

const SessionExpiredModal = ({ isOpen }) => {
  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => {
        window.location.reload(); 
      }, 5000); 
      return () => clearTimeout(timer); 
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
      }}
    >
      <div
        style={{
          backgroundColor: 'white',
          padding: '20px',
          borderRadius: '8px',
          textAlign: 'center',
          maxWidth: '400px',
        }}
      >
        <p>Your session has expired.<br />
        To restore your session, please refresh the page or It will auto refresh in a few seconds.</p>
      </div>
    </div>
  );
};

export default SessionExpiredModal;
