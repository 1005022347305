import React, { useState, useEffect } from 'react';
import Header from '../../assets/header/Header.js';
import Sidebar from '../../assets/sidebar/sidebar.js';
import { AddResponseEvents, GetAllResponseEvents, UpdateResponseEventsStatus } from '../../services/adminServices.js';
import { useModal } from '../modalPopup/modelcontext.js';
import 'react-datepicker/dist/react-datepicker.css';
import { Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import SearchableDropdown from '../login_signup/searchableDropdown.js';
import { GetAllCountries, GetAllEventSubTypes, GetAllEventTypes, GetAllScenarios } from '../../services/dropdownServices.js';

const ResponseEvent = () => {
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [allEventTypes, setAllEventTypes] = useState([]);
    const [allEventSubTypes, setAllEventSubTypes] = useState([]);
    const [allCountries, setAllCountries] = useState([]);
    const [allScenarios, setAllScenarios] = useState([]);
    const [allResponseEvents, setAllResponseEvents] = useState([]);
    const [eventRows, setEventRows] = useState([{ eventType: '', eventSubType: '', filteredEventSubTypes: [] }]);
    const [scenarioRows, setScenarioRows] = useState([{ scenario: '' }]);
    const [countryRows, setCountryRows] = useState([{ country: '' }]);
    const [responseDescription, setResponseDescription] = useState('');
    const [resYear, setResYear] = useState('');
    const [responseEventId, setResponseEventId] = useState(0);
    const [responseCode, setResponseCode] = useState('');
    const [errors, setErrors] = useState({});
    const { showModal } = useModal();
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState('');   
    const [btnSaveText, setBtnSaveText] = useState('');
    const [isEditClicked, setIsEditClicked] = useState(false);
    const [isActiveRecords, setIsActiveRecords] = useState(false);
    const currentYear = new Date().getFullYear();
    const minYear = 1900;
    ///Modal Popup
    const [show, setShow] = useState(false);
    const [modalSelectedEvent, setModalSelectedEvent] = useState(null);
    const [selectedField, setSelectedField] = useState('');
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [eventToUpdate, setEventToUpdate] = useState(null);

    const handleAddEventClick = () => {
        setIsFormVisible(true);
        setIsEditClicked(false);
        setBtnSaveText('Save');
      };

    const handleCancelClick = () => {
        setIsFormVisible(false);
        clearControls();
    };

    const handleKeyPress = (event) => {
        const { key } = event;
        if (!(
            (key >= '0' && key <= '9') || 
            key === 'Backspace' || 
            key === 'Enter' || 
            key === 'Tab' || 
            key === 'ArrowLeft' || 
            key === 'ArrowRight' || 
            key === 'Delete' ||
            key === 'Home' || 
            key === 'End'
        )) {
        event.preventDefault();
        }
    };

    const handleInputChange = async (e, index, field, type) => {
        const value = e.target.value;
        const updateRows = (rows, setRows) => {
            const newRows = [...rows];
            newRows[index][field] = value;
            if (type === 'event' && field === 'eventType') {
                newRows[index].eventSubType = '';  // Reset eventSubType
                const filteredEventSubTypes = allEventSubTypes.filter(subType => subType.eventTypeId === parseInt(value));
                newRows[index].filteredEventSubTypes = filteredEventSubTypes;
            }
            setRows(newRows);
        };
        switch (type) {
            case 'event':
                updateRows(eventRows, setEventRows);
                break;
            case 'scenario':
                updateRows(scenarioRows, setScenarioRows);
                break;
            case 'country':
                updateRows(countryRows, setCountryRows);
                break;
            case 'response':
                setResponseDescription(value);
                break;
            case 'year':
                setResYear(value);
                break;
            case 'resCode':
                setResponseCode(value);                
                break;
            default:
                break;
        }
    };

    const addRow = (type) => {
        switch (type) {
            case 'event':
                setEventRows([...eventRows, { eventType: '', eventSubType: '', filteredEventSubTypes: [] }]);
                break;
            case 'scenario':
                setScenarioRows([...scenarioRows, { scenario: '' }]);
                break;
            case 'country':
                setCountryRows([...countryRows, { country: '' }]);
                break;
            default:
                break;
        }
    };
    
    const removeRow = (index, type) => {
        switch (type) {
            case 'event':
                setEventRows(eventRows.filter((_, i) => i !== index));
                break;
            case 'scenario':
                setScenarioRows(scenarioRows.filter((_, i) => i !== index));
                break;
            case 'country':
                setCountryRows(countryRows.filter((_, i) => i !== index));
                break;
            default:
                break;
        }
    };    

    const getEventTypes = async () => {
        try {
            GetAllEventTypes().then((response) => {
                if (response.status === 200) {
                    setAllEventTypes(response.data);                
                  }       
             });            
        } catch (error) {
            console.error('Error occurred while fetching  event types: ', error);
        }
    };

    const getEventSubTypes = async () => {
        try {
            GetAllEventSubTypes().then((response) => {
                if (response.status === 200) {
                    setAllEventSubTypes(response.data);    
                }       
             });            
        } catch (error) {
            console.error('Error occurred while fetching event sub types: ', error);
        }
    };

    const getCountries = async () => {
        try {
            GetAllCountries().then((response) => {
                if (response.status === 200) {
                    setAllCountries(response.data);    
                }       
             });            
        } catch (error) {
            console.error('Error occurred while fetching countries: ', error);
        }
    };

    const getScenarios = async () => {
        try {
            GetAllScenarios().then((response) => {
                if (response.status === 200) {
                    setAllScenarios(response.data);    
                }       
             });            
        } catch (error) {
            console.error('Error occurred while fetching scenarios: ', error);
        }
    };

    const fetchResponseEventsDetails = async() => {
        try{
            GetAllResponseEvents().then((response) =>{
                if(response.status === 200){
                    setAllResponseEvents(response.data);
                }
            });
        }catch(error){
            console.error('Error while fetching capacity details:', error);
        }
    };

    useEffect(() => { 
        getEventTypes();
        getEventSubTypes();
        getCountries();
        getScenarios();
        fetchResponseEventsDetails();
    }, []);
 
    // Function to calculate pagination and filter data
    const filteredResponseEvents = allResponseEvents.filter((resevent) =>(
        (!isActiveRecords || resevent.isActive) && (
        (resevent.eventTypes && resevent.eventTypes.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (resevent.eventSubTypes && resevent.eventSubTypes.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (resevent.scenarios && resevent.scenarios.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (resevent.countries && resevent.countries.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (resevent.responseDescription && resevent.responseDescription.toLowerCase().includes(searchQuery.toLowerCase()))||
        (resevent.responseCode && resevent.responseCode.toLowerCase().includes(searchQuery.toLowerCase()))||
        (resevent.responseYear && resevent.responseYear.toString().includes(searchQuery)) 
        )
    ));

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredResponseEvents.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredResponseEvents.length / itemsPerPage);
    const goToPage = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const startRecord = indexOfFirstItem + 1;
    const endRecord = Math.min(indexOfLastItem, filteredResponseEvents.length);
    //End Pagination logic here 
    
    const validate = () => {
        const newErrors = {};
        const eventSubTypes = new Set();
        const scenarios = new Set();
        const countries = new Set();
        const responseCodeRegex = /^[^-]+$/;
        eventRows.forEach((row, index) => {
            if (!row.eventType) {
                newErrors[`eventType-${index}`] = 'Event Type is required';
            }
            if (!row.eventSubType) {
                newErrors[`eventSubType-${index}`] = 'Event Sub Type is required';
            } else if (eventSubTypes.has(row.eventSubType)) {
                newErrors[`eventSubType-${index}`] = 'Duplicate Event Sub Type found, Please add different Event Sub Type';
            } else {
                eventSubTypes.add(row.eventSubType);
            }
        });
        scenarioRows.forEach((row, index) => {
            if (!row.scenario) {
                newErrors[`scenario-${index}`] = 'Scenario is required';
            }
            else if (scenarios.has(row.scenario)) {
                newErrors[`scenario-${index}`] = 'Duplicate Scenario found, Please add different Scenario';
            } else {
                scenarios.add(row.scenario);
            }
        });
        countryRows.forEach((row, index) => {
            if (!row.country ||row.country===0) {
                newErrors[`country-${index}`] = 'Country is required';
            } else if (countries.has(row.country)) {
                newErrors[`country-${index}`] = 'Duplicate Country found, Please add different Country';
            } else {
                countries.add(row.country);
            }
        });
        if (!responseDescription) {
            newErrors.responseDescription = 'Response Description is required';
        }
        if (!resYear) {
            newErrors.resYear = 'Year is required';
        } else if (parseInt(resYear, 10) < minYear || parseInt(resYear, 10) > currentYear) {
            newErrors.resYear = `Year must be between ${minYear} and ${currentYear}`;
        }
        if(!responseCode){
            newErrors.responseCode = 'Response Code is required';
        }
        else if(responseCode && !responseCodeRegex.test(responseCode)){
            newErrors.responseCode = 'Invalid Response Code. Hyphens are not allowed in this field, please remove hyphens and try again';
        }
        else if(responseCode){
            const isDuplicate = allResponseEvents.some(
                (event) => event.responseCode === responseCode && event.responseId !== responseEventId
              );
              if (isDuplicate) {
                newErrors.responseCode = 'This response code already exists. Please enter a unique code.';
              }
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const clearControls=()=>{
        setEventRows([{ eventType: '', eventSubType: '' }]);
        setScenarioRows([{ scenario: '' }]);
        setCountryRows([{ country: '' }]);
        setResponseDescription('');
        setResYear('');
        setResponseCode('');
        setErrors({});
    }

    const saveResponseEvents = async () =>{
        try {
            if (!validate()) {
              return;
            }
            const scenarioDetails = scenarioRows.map(row => {
                const value = parseInt(row.scenario, 10);
                return isNaN(value) ? null : value;
            }).filter(value => value !== null); 
        
            const countryDetails = countryRows.map(row => {
                const value = parseInt(row.country, 10);
                return isNaN(value) ? null : value; 
            }).filter(value => value !== null); 
            var payload = {
                responseId :responseEventId,
                responseDescription : responseDescription,
                responseCode : responseCode,
                responseYear: parseInt(resYear,10), 
                eventDetails: eventRows.map(row => ({
                    eventTypeId: parseInt(row.eventType, 10),
                    eventSubTypeId: parseInt(row.eventSubType, 10)
                })),
                scenarioDetails: scenarioDetails,
                countryDetails: countryDetails
            };
              AddResponseEvents(payload).then((res) => {
               if (res.data.success) {
                showModal('success',res.data.message);
                clearControls();
                fetchResponseEventsDetails();
              } else {
                showModal('failure',res.data.message);
              }       
            });
          } 
          catch (error) {
            showModal('failure','Adding Response Events failed, Please try again later.');
          }
    };

    const handleUpdateClick = (reponseEvent) => {
        setIsFormVisible(true);
        setIsEditClicked(true);
        setBtnSaveText('Update');
        setResponseEventId(reponseEvent.responseId);
        setResponseDescription(reponseEvent.responseDescription);
        setResYear(reponseEvent.responseYear || '');
        setResponseCode(reponseEvent.responseCode);
        setEventRows(reponseEvent.eventTypeSubTypeDetails.map(detail => ({
            eventType: detail.eventTypeId,
            eventSubType: detail.eventSubTypeId,
            filteredEventSubTypes: allEventSubTypes.filter(subType => subType.eventTypeId === detail.eventTypeId)
        })));

        setScenarioRows(reponseEvent.responseScenarios.map(detail => ({
            scenario: detail.scenarioId
        })));

        setCountryRows(reponseEvent.responseCountries.map(detail => ({
            country: detail.countryId
        })));
    };

    const handleClose = () => {
        setShow(false);
        setSelectedField('');
    };

    const handleShow = (event, field) => {
        setModalSelectedEvent(event);
        setSelectedField(field);
        setShow(true);
    };

    const getFieldTitle = (field) => {
        switch (field) {
            case 'eventTypes':
                return 'Event Type';
            case 'eventSubTypes':
                return 'Event Sub Type';
            case 'scenarios':
                return 'Scenarios';
            case 'countries':
                return 'Countries';
            default:
                return 'Details';
        }
    };

    ///Update the status with confirm box
    const updateStatus = (responseEvent) => {
        setEventToUpdate(responseEvent);
        setShowUpdateModal(true);
    };
    
    const handleStatusClose = () => setShowUpdateModal(false);
    
    const handleConfirmUpdate = () => {
        if (eventToUpdate) {
            const updatedStatus = !eventToUpdate.isActive;
            const payload = { responseId: eventToUpdate.responseId, isActive: updatedStatus };
            
            UpdateResponseEventsStatus(payload).then((res) => {
                if (res.data.success) {
                    showModal('success', res.data.message);
                    fetchResponseEventsDetails();
                } else {
                    showModal('failure', res.data.message);
                }
            });
        }
        setShowUpdateModal(false);
    };

    return (
        <>
            <Header />
            <div className="d-flex">
                <Sidebar />
                <div className="main bg-light-iom">
                    <div className='dashboard-class'>
                        <div className='cont-wrapper'>
                            <div className="container mt-5">
                                <div className="mb-3">
                                    <div className='row'>
                                        <div className="col-md-6">
                                            <h4>Response Events</h4>
                                        </div>
                                        <div className="col-md-6 text-md-right" style={{textAlign:'right'}}>
                                            <button className="btn btn-primary" onClick={handleAddEventClick}>Add Response</button>
                                        </div>
                                    </div>
                                </div>
                                {isFormVisible && (
                                <div className="card mb-3">
                                    <div className="card-header">
                                    <h6>Response Information</h6>
                                    </div>
                                    <div className='card-body'>
                                        <div className='responseevents'>
                                        <titletext>Event Details</titletext>
                                        </div>                                        
                                        {eventRows.map((row, index) => (
                                            <div key={index}>
                                                <div className='row'>
                                                    <div className="form-group mb-3 col-md-5">
                                                        <label htmlFor={`eventType-${index}`} className='form-label'>Event Type <span style={{color:"red", fontWeight: "bold"}}>*</span></label>
                                                        <select id={`eventType-${index}`} className="form-select" value={row.eventType} onChange={(e) => handleInputChange(e, index, 'eventType', 'event')}>
                                                            <option value="">--Select--</option>
                                                            {allEventTypes.map((event) => (
                                                                <option key={event.eventTypeId} value={event.eventTypeId}>{event.eventTypeName}</option>
                                                            ))}
                                                        </select>
                                                        {errors[`eventType-${index}`] && <p style={{color: 'red'}}>{errors[`eventType-${index}`]}</p>}
                                                    </div>
                                                    <div className="form-group mb-3 col-md-5">
                                                        <label htmlFor={`eventSubType-${index}`} className='form-label'>Event Sub Type <span style={{color:"red", fontWeight: "bold"}}>*</span></label>
                                                        <select id={`eventSubType-${index}`} className="form-select" value={row.eventSubType} onChange={(e) => handleInputChange(e, index, 'eventSubType', 'event')}>
                                                            <option value="">--Select--</option>
                                                            {row.filteredEventSubTypes && row.filteredEventSubTypes.map((subType) => (
                                                                <option key={subType.eventSubTypeId} value={subType.eventSubTypeId}>{subType.eventSubTypeName}</option>
                                                            ))}
                                                        </select>
                                                        {errors[`eventSubType-${index}`] && <p style={{color: 'red'}}>{errors[`eventSubType-${index}`]}</p>}
                                                    </div> 
                                                    <div className="form-group col-md-2 d-flex align-items-center" style={{ marginTop: '25px' }}>
                                                        <button type="button" className="btn btn-primary mb-2 me-2" onClick={() => addRow('event')}><i className="bi bi-plus-circle"></i></button>
                                                        {eventRows.length > 1 && (
                                                            <button type="button" className="btn btn-danger mb-2" onClick={() => removeRow(index, 'event')}><i className="bi bi-dash-circle"></i></button>
                                                        )}
                                                    </div>                                                  
                                                </div>                                                
                                            </div>                                            
                                        ))}
                                        <div className='responseevents'>
                                        <titletext>Scenarios Details</titletext>
                                        </div> 
                                        {scenarioRows.map((row, index) => (
                                            <div key={index}>
                                                <div className='row'>
                                                    <div className="form-group mb-3 col-md-4">
                                                        <label htmlFor={`scenario-${index}`} className="form-label">Scenario <span style={{color:"red", fontWeight: "bold"}}>*</span></label>
                                                        <select id={`scenario-${index}`} className="form-select" value={row.scenario} onChange={(e) => handleInputChange(e, index, 'scenario', 'scenario')}>
                                                            <option value="">--Select--</option>
                                                            {allScenarios.map((item) => (
                                                                <option key={item.scenarioId} value={item.scenarioId}>{item.scenarioName}</option>
                                                            ))}
                                                        </select>
                                                        {errors[`scenario-${index}`] && <p style={{color: 'red'}}>{errors[`scenario-${index}`]}</p>}
                                                    </div>
                                                    <div className="form-group col-md-2 d-flex align-items-center" style={{ marginTop: '25px' }}>
                                                        <button type="button" className="btn btn-primary mb-2 me-2" onClick={() => addRow('scenario')}><i className="bi bi-plus-circle"></i></button>
                                                        {scenarioRows.length > 1 && (
                                                            <button type="button" className="btn btn-danger mb-2" onClick={() => removeRow(index, 'scenario')}><i className="bi bi-dash-circle"></i></button>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        <div className='responseevents'>
                                        <titletext>Countries details</titletext>
                                        </div>
                                        {countryRows.map((row, index) => (
                                            <div key={index}>
                                                <div className='row'>
                                                    <div className="form-group mb-3 col-md-4">
                                                        <label htmlFor={`country-${index}`} className="form-label">Country <span style={{color:"red", fontWeight: "bold"}}>*</span></label>
                                                        <SearchableDropdown options={allCountries} selectedValue={row.country} onChange={(value) => handleInputChange({ target: { value } }, index, 'country', 'country')}
                                                            className="form-select"
                                                            displayKey="countryName"
                                                            valueKey="countryId" 
                                                            disabled={false} />
                                                        {errors[`country-${index}`] && <p style={{color: 'red'}}>{errors[`country-${index}`]}</p>}
                                                    </div>
                                                    <div className="form-group col-md-2 d-flex align-items-center" style={{ marginTop: '25px' }}>
                                                        <button type="button" className="btn btn-primary mb-2 me-2" onClick={() => addRow('country')}><i className="bi bi-plus-circle"></i></button>
                                                        {countryRows.length > 1 && (
                                                            <button type="button" className="btn btn-danger mb-2" onClick={() => removeRow(index, 'country')}><i className="bi bi-dash-circle"></i></button>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        <div className='responseevents'>
                                        <titletext>Response Details</titletext>
                                        </div>
                                        <div className='row'>
                                            <div className="form-group col-md-3">
                                                <label htmlFor="responseDesc" className="form-label">Response Description <span style={{color:"red", fontWeight: "bold"}}>*</span></label>
                                                <input type="text" className="form-control" id="responseDesc" placeholder="Response Description" value={responseDescription} onChange={(e) => handleInputChange(e, 0, 'responseDesc', 'response')}/>
                                                {errors.responseDescription && <p style={{color: 'red'}}>{errors.responseDescription}</p>}
                                            </div>
                                            <div className="form-group col-md-2">
                                                <label htmlFor="year" className="form-label">Year <span style={{color:"red", fontWeight: "bold"}}>*</span></label>
                                                <input type="text" className="form-control" id="year" placeholder="Year" value={resYear} onChange={(e) => handleInputChange(e, 0, 'year', 'year')} onKeyDown={handleKeyPress}/>
                                                {errors.resYear && <p style={{color: 'red'}}>{errors.resYear}</p>}
                                            </div>
                                            <div className="form-group col-md-3">
                                                <label htmlFor="responseCode" className="form-label">Response Code <span style={{color:"red", fontWeight: "bold"}}>*</span></label>
                                                <input type="text" className="form-control" id="responseDesc" placeholder="Response Code" value={responseCode} onChange={(e) => handleInputChange(e, 0, 'responseCode', 'resCode')}/>
                                                {errors.responseCode && <p style={{color: 'red'}}>{errors.responseCode}</p>}
                                            </div>
                                            <div className="form-group col-md-4" style={{ marginTop: '32px',textAlign:'right' }}>
                                                <button type="submit" id="btnSave" className="btn btn-primary me-2" onClick={saveResponseEvents}>{btnSaveText}</button>
                                                {!isEditClicked && (
                                                    <button type="reset" className="btn btn-secondary me-2" onClick={clearControls}>Clear Form</button>
                                                )}
                                                <button type="button" className="btn btn-danger" onClick={handleCancelClick}>Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                )}                                
                                <div id="teamdetailsList" className='card mb-3'>
                                    <div className="card-header">
                                        <h6>Response Events List</h6>
                                    </div>
                                        <div className='card-body'>
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                            <div>
                                                Show{' '}
                                                    <select value={itemsPerPage} onChange={(e) => setItemsPerPage(Number(e.target.value))} className="form-select d-inline-block w-auto">
                                                        <option value={5}>5</option>
                                                        <option value={10}>10</option>
                                                        <option value={20}>20</option>
                                                        <option value={50}>50</option>
                                                    </select>{' '}
                                                entries
                                            </div>
                                            <div className="d-flex align-items-center w-50">
                                            <label className="form-check-label me-2 w-25">
                                                <input type="checkbox" className="form-check-input me-2" checked={isActiveRecords} onChange={(e) => setIsActiveRecords(e.target.checked)} /> 
                                                Is Active </label>
                                                <input type="text" className="form-control me-2 w-30" placeholder="Search..." value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
                                            </div>
                                        </div>          
                                        <div className="table-responsive" style={{fontSize:"0.9rem"}}>
                                            <table className="table table-bordered table-striped">
                                                <thead className="thead-light" style={{background:"ghostwhite"}}>
                                                    <tr>
                                                        <th style={{background:"#7a9fd9", textAlign: 'center', verticalAlign: 'middle'}}>Event Type</th>
                                                        <th style={{background:"#7a9fd9", textAlign: 'center', verticalAlign: 'middle'}}>Event Sub Type</th>
                                                        <th style={{background:"#7a9fd9", textAlign: 'center', verticalAlign: 'middle'}}>Scenarios</th>
                                                        <th style={{background:"#7a9fd9", textAlign: 'center', verticalAlign: 'middle'}}>Cuntries</th>
                                                        <th style={{background:"#7a9fd9", textAlign: 'center', verticalAlign: 'middle'}}>Response Description</th>
                                                        <th style={{background:"#7a9fd9", textAlign: 'center', verticalAlign: 'middle'}}>Year</th>
                                                        <th style={{background:"#7a9fd9", textAlign: 'center', verticalAlign: 'middle'}}>Response Code</th>
                                                        <th style={{background:"#7a9fd9", textAlign: 'center', verticalAlign: 'middle'}}>Status</th>
                                                        <th style={{background:"#7a9fd9", textAlign: 'center', verticalAlign: 'middle'}}>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {currentItems.map((event) => (
                                                    <tr key={event.responseId}>
                                                    <td>
                                                        {event.eventTypes.substring(0, 20)}
                                                        {event.eventTypes.length > 20 && (
                                                            <a href="#" onClick={() => handleShow(event, 'eventTypes')}>...View More</a>
                                                        )}
                                                    </td>
                                                    <td>
                                                        {event.eventSubTypes.substring(0, 20)}
                                                        {event.eventSubTypes.length > 20 && (
                                                            <a href="#" onClick={() => handleShow(event, 'eventSubTypes')}>...View More</a>
                                                        )}
                                                    </td>
                                                    <td>
                                                        {event.scenarios.substring(0, 20)}
                                                        {event.scenarios.length > 20 && (
                                                            <a href="#" onClick={() => handleShow(event, 'scenarios')}>...View More</a>
                                                        )}
                                                    </td>
                                                    <td>
                                                        {event.countries.substring(0, 20)}
                                                        {event.countries.length > 20 && (
                                                            <a href="#" onClick={() => handleShow(event, 'countries')}>...View More</a>
                                                        )}
                                                    </td>
                                                    <td>{event.responseDescription}</td>
                                                    <td>{event.responseYear}</td>
                                                    <td>{event.responseCode}</td>
                                                    <td>{event.isActive ? 'Open' : 'Closed'}</td>
                                                    <td>
                                                        <button className="btn btn-sm me-2" onClick={() => handleUpdateClick(event)} data-bs-toggle="tooltip" data-bs-placement="top" data-bs-html="true" title="Edit">
                                                        <span className='bi bi-pencil' style={{color:'#ffc107'}}/>
                                                        </button>
                                                        <button className='btn btn-sm' style={{ color: event.isActive ? 'red' : 'green' }} onClick={()=>updateStatus(event)}>{event.isActive ? <i className="bi bi-x-circle" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-html="true" title="Close"></i> : <i className="bi bi-arrow-repeat" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-html="true" title="Re-Open"></i>}</button>
                                                    </td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <span>
                                                Showing {startRecord} to {endRecord} of {allResponseEvents.length} records
                                            </span>
                                            <nav aria-label="Page navigation example">
                                                <ul className="pagination justify-content-end">
                                                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                        <button className="page-link" onClick={() => goToPage(currentPage - 1)}><span className='pi pi-perviousbi bi-skip-backward-fill'></span></button>
                                                    </li>
                                                    {Array.from({ length: totalPages }, (_, index) => (
                                                    <li key={index + 1} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                                                        <button className="page-link" onClick={() => goToPage(index + 1)}>{index + 1}</button>
                                                    </li>
                                                    ))}
                                                    <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                                        <button className="page-link" onClick={() => goToPage(currentPage + 1)}><span className='bi bi-skip-forward-fill'/></button>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                        <Modal show={show} onHide={handleClose} centered>
                                            <Modal.Header closeButton>
                                                <Modal.Title className="text-center w-100">{getFieldTitle(selectedField)}</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body className="modal-body-scrollable">
                                                {modalSelectedEvent && (
                                                    <>
                                                        {selectedField === 'eventTypes' && modalSelectedEvent.eventTypes.split(',').map((item, index) => (
                                                                                        <p key={index}>{item.trim()}</p>
                                                                                        ))}
                                                        {selectedField === 'eventSubTypes' && modalSelectedEvent.eventSubTypes.split(',').map((item, index) => (
                                                                                        <p key={index}>{item.trim()}</p>
                                                                                        ))}
                                                        {selectedField === 'scenarios' && modalSelectedEvent.scenarios.split(',').map((item, index) => (
                                                                                        <p key={index}>{item.trim()}</p>
                                                                                        ))}
                                                        {selectedField === 'countries' && modalSelectedEvent.countries.split(',').map((item, index) => (
                                                                                        <p key={index}>{item.trim()}</p>
                                                                                        ))}
                                                    </>
                                                )}
                                            </Modal.Body>
                                        </Modal>
                                        <Modal show={showUpdateModal} onHide={handleStatusClose} centered>
                                            <Modal.Header>
                                                <Modal.Title className="text-center w-100">Confirm Status Update</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body className="modal-body-scrollable">
                                                <p>
                                                    {eventToUpdate?.isActive 
                                                        ? 'Are you sure you want to close this event?' 
                                                        : 'Are you sure you want to open this event?'}
                                                </p>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <button type='button' className="btn btn-secondary me-2"  onClick={handleStatusClose}>
                                                    Cancel
                                                </button>
                                                <button type='button' className="btn btn-primary" onClick={handleConfirmUpdate}>
                                                    Update
                                                </button>
                                            </Modal.Footer>
                                        </Modal>
                                    </div>                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default ResponseEvent;
