import React, { useState, useEffect } from 'react';
import Header from '../../assets/header/Header.js';
import Sidebar from '../../assets/sidebar/sidebar.js';
import SearchableDropdown from '../login_signup/searchableDropdown.js';
import { useModal } from '../modalPopup/modelcontext.js';
import { GetUserDetailsOffices, GetTeamDetailsOffices, GetAllTeams, GetAllCountries } from '../../services/dropdownServices.js';
import { GetUserDetailsByUserId, UpdateMyProfileData } from '../../services/adminServices.js'

const MyProfile = () =>{
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userAccountType, setUserAccountType] = useState('');
    const [userJobTitle,setUserJobTitle] = useState('');
    const [selectedUserOffice, setSelectedUserOffice] = useState({officeId : 0, officeDescription : ''});
    const [offices, setOffices] = useState([{ officeId: 0, officeDescription: '' }]);
    const [selectedUserTeam, setSelectedUserTeam] = useState({ teamId: '', teamName: '' });
    const [nationality, setNationality] = useState(null);
    const [secondnationality, setSecondNationality] = useState(null);
    const [hasUnlp, setHasUnlp] = useState(false);
    const [allTeams,setAllTeams] = useState([]);
    const [allOffices, setAllOffices] = useState([]);
    const [userdetailsAllOffices, setUserdetailsAllOffices] = useState([]);
    const [nationalities, setNationalities] = useState([]);
    const [errors, setErrors] = useState({});
    const { showModal } = useModal();
    const [userLoginId, setUserLoginId] = useState(0);

    const fetchTeams = async () => {
        try {
            GetAllTeams().then((response) => {
                if (response.status === 200) {
                    setAllTeams(response.data); 
                }       
             });            
        } catch (error) {
            console.error('Error fetching teams: ', error);
        }
    };

    const fetchGeneralOffices = async () => {
        try {
            GetTeamDetailsOffices().then((response) => {
                if (response.status === 200) {
                    setAllOffices(response.data); 
                }       
             });            
        } catch (error) {
            console.error('Error fetching teams: ', error);
        }
    };

    const fetchUserDetailsOffices = async () => {
        try {
            GetUserDetailsOffices().then((response) => {
                if (response.status === 200) {
                    setUserdetailsAllOffices(response.data); 
                }       
             });            
        } catch (error) {
            console.error('Error fetching teams: ', error);
        }
    };

    const fetchNationality = async () => {
        try{
            GetAllCountries().then((response) => {
                if(response.status === 200){
                    setNationalities(response.data);
                }
            });
        } catch (error){
            console.error('Error while fetching nationality: ', error);
        }
    };

    const fetchUserDetailsBasedOnUserId = () => {
        try{
            GetUserDetailsByUserId().then((response) => {
                if(response.status === 200){
                    setUserLoginId(response.data.userLoginId);
                    setFirstName(response.data.firstName);
                    setLastName(response.data.lastName);
                    setUserEmail(response.data.email);
                    setUserAccountType(response.data.accountType);
                    setUserJobTitle(response.data.jobTitle);
                    setSelectedUserOffice({
                        officeId: response.data.officeId || '',
                        officeDescription: response.data.officeDescription || ''
                    });
                    if (response.data.offices && Array.isArray(response.data.offices) && response.data.offices.length > 0) {
                        const filteredOffices = response.data.offices.filter(office => office.officeId !== response.data.officeId);
                        if(filteredOffices.length > 0){
                            setOffices(filteredOffices.map(detail => ({
                                officeId: detail.officeId || '',
                                officeDescription: detail.officeDescription || ''
                            })));
                        }else {
                            setOffices([{ officeId: 0, officeDescription: '' }]);
                        }            
                    }        
                    setSelectedUserTeam({
                        teamId: response.data.teamId || '',
                        teamName: response.data.teamName || ''
                    });
                    setNationality(response.data.nationalityId);
                    setSecondNationality(response.data.secondNationalityId);
                    setHasUnlp(response.data.has_UNLP);
                }
            });
        } catch (error){
            console.error('Error while fetching userdetails: ', error);
        }
    };

    useEffect(() => { 
        fetchTeams();
        fetchGeneralOffices();
        fetchUserDetailsOffices();
        fetchNationality();
    }, []);

    useEffect(() => {
        fetchUserDetailsBasedOnUserId();
    }, []);

    const validate = () => {
        const newErrors = {};
        if (!firstName) newErrors.firstName = 'First name is required.';
        if (!lastName) newErrors.lastName = 'Last name is required.';
        if (!userJobTitle) newErrors.userJobTitle = 'Job Title is required.';
        if (!nationality) newErrors.nationality = 'Nationality is required.';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleCheckboxChange = (e) => {
        setHasUnlp(e.target.checked);
    };

    const saveUserData = async () =>{
        try {
            if (!validate()) {
              return;
            }
            const payload = {
                userLoginId,
                firstName,
                lastName,
                jobTitle: userJobTitle,
                nationalityId: parseInt(nationality),
                secondNationalityId: parseInt(secondnationality),
                has_UNLP: hasUnlp
            };
            UpdateMyProfileData(payload).then((res) => {
               if (res.data.success) {
                    showModal('success',res.data.message);                                
                } else {
                showModal('failure',res.data.message);
                }       
            });
        } 
        catch (error) {
            showModal('failure','Failed to update user details, Please try again later.');
        }
    };

    return (
        <>
            <Header />
            <div className="d-flex">
                <Sidebar />
                <div className="main bg-light-iom">
                    <div className='dashboard-class'>
                        <div className='cont-wrapper'>
                            <div className="container mt-5">
                                <div className="mb-3">
                                    <div className='row'>
                                        <div className="col-md-6">
                                            <h4>My Profile</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="card mb-3">
                                    <div className="card-header">
                                    <h6>User Detail</h6>
                                    </div>
                                    <div className='card-body'>
                                        <div className='responseevents'>
                                        <titletext>Personal Details</titletext>
                                        </div>
                                        <div className='row'>
                                            <div className="form-group mb-3 col-md-4">
                                                <label htmlFor="firstName" className="form-label">First Name <span style={{color:"red", font:"bold"}}>*</span></label>
                                                <input type="text" className="form-control" id="firstName" placeholder="First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)}/>
                                                {errors.firstName && <p style={{color: 'red'}}>{errors.firstName}</p>}   
                                            </div>
                                            <div className="form-group mb-3 col-md-4">
                                                <label htmlFor="lastName" className="form-label">Last Name <span style={{color:"red", font:"bold"}}>*</span></label>
                                                <input type="text" className="form-control" id="lastName" placeholder="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)}/>
                                                {errors.lastName && <p style={{color: 'red'}}>{errors.lastName}</p>} 
                                            </div>
                                            <div className="form-group mb-3 col-md-4">
                                                <label htmlFor="email" className="form-label">Email</label>
                                                <input type="text" className="form-control" id="email" placeholder="Email" disabled value={userEmail} />
                                            </div>
                                        </div>
                                        <div className='row'>  
                                            <div className="form-group mb-3 col-md-4">
                                                <label htmlFor="accountType" className='form-label'>Account Type</label>
                                                <input type="text" className="form-control" id="accountType" placeholder="Account Type" disabled value={userAccountType} />
                                            </div>                                          
                                            <div className="form-group mb-3 col-md-4">
                                                <label htmlFor="jobTitle" className='form-label'>Job Title <span style={{color:"red", font:"bold"}}>*</span></label>
                                                <input type="email" className="form-control" id="jobTitle" placeholder="Job Title" value={userJobTitle} onChange={(e) => setUserJobTitle(e.target.value)}/>
                                            </div>
                                            <div className="form-group mb-3 col-md-4">
                                                <label htmlFor="office" className='form-label'>Office</label>
                                                <SearchableDropdown options={allOffices} selectedValue={selectedUserOffice.officeId} displayKey="officeDescription" valueKey="officeId" className="w-100" disabled={true}/>
                                            </div>                                            
                                        </div>
                                        <div className='row'>  
                                            <div className="form-group mb-3 col-md-4">
                                                <label htmlFor="nationality" className='form-label'>Nationality <span style={{color:"red", font:"bold"}}>*</span></label>
                                                <select id="nationality" className="form-select" value={nationality} onChange={(e) => setNationality(e.target.value)}>
                                                    {nationalities.map((nat) => (
                                                        <option key={nat.countryId} value={nat.countryId}>{nat.countryName}</option>
                                                    ))}
                                                </select>
                                                {errors.nationality && <p style={{color: 'red'}}>{errors.nationality}</p>} 
                                            </div>                                          
                                            <div className="form-group mb-3 col-md-4">
                                                <label htmlFor="secondnationality" className='form-label'>Second Nationality </label>
                                                <select id="secondnationality" className="form-select" value={secondnationality} onChange={(e) => setSecondNationality(e.target.value)}>
                                                    {nationalities.map((nationality) => (
                                                        <option key={nationality.countryId} value={nationality.countryId}>{nationality.countryName}</option>
                                                    ))}
                                                </select>
                                                {errors.secondnationality && <p style={{color: 'red'}}>{errors.secondnationality}</p>} 
                                            </div>                                        
                                            <div className="form-group mb-3 col-md-4">
                                                <label htmlFor="team" className='form-label'>Team</label>
                                                <select id="team" className="form-select" value={selectedUserTeam.teamId} disabled>
                                                    <option value="">--Select--</option>
                                                    {allTeams.map((team) => (
                                                        <option key={team.teamId} value={team.teamId}>{team.teamName}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div> 
                                        <div className='row'>                                            
                                            <div className="form-group mb-3 col-md-3">
                                                <input type='checkbox' id="hasunlp" className='me-2 mb-2' checked={hasUnlp} onChange={handleCheckboxChange}/>
                                                <label htmlFor="hasunlp" className='form-label'>Do you have UNLP?</label>
                                            </div>
                                            <div className="form-group mb-3 col-md-9" style={{textAlign:"right"}}>
                                                <button type="submit" id="btnSave" className="btn btn-primary me-2 mb-2" onClick={saveUserData}>Update</button> 
                                            </div>
                                        </div>                                       
                                        <div className='responseevents'>
                                            <titletext>Duty stations </titletext>
                                        </div>                                        
                                        <div className='row'>
                                            <div className="form-group mb-3 col-md-6">
                                                <label htmlFor='office' className='form-label'>
                                                    Assigned Duty station
                                                </label>
                                            {offices.map((office, index) => (
                                            <div key={index} className='mb-3'>  
                                                <SearchableDropdown
                                                    options={userdetailsAllOffices}
                                                    selectedValue={office.officeId}
                                                    displayKey="officeDescription"
                                                    valueKey="officeId"
                                                    className="w-100" 
                                                    disabled={true}
                                                />
                                                {errors[`office-${index}`] && <p style={{ color: 'red' }}>{errors[`office-${index}`]}</p>}                                                        
                                            </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    );
};

export default MyProfile;