import React, { useState, useEffect } from 'react';
import { Dropdown, FormControl } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import './login.css';

const SearchableDropdown = ({ options, selectedValue, onChange,displayKey = 'label',valueKey = 'value',placeholder = '--Select--', className, disabled = false }) => {
  const [searchTerm, setSearchTerm] = useState('');  
  const uniqueId = uuidv4();
  const [filteredOptions, setFilteredOptions] = useState(options);
  
  useEffect(() => {
    setFilteredOptions(options.filter((option) =>
      option[displayKey].toLowerCase().includes(searchTerm.toLowerCase())
    ));
  }, [options, searchTerm, displayKey]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSelect = (value) => {
    if (!disabled) {
      onChange(value);
      setSearchTerm('');
    }
  };

  const selectedOption = options.find((option) => option[valueKey] === selectedValue);

  return (
    <Dropdown>
      <Dropdown.Toggle 
        variant="success" 
        id={`dropdown-basic-${uniqueId}`} 
        className={`form-select ${className}`} 
        style={{ backgroundColor: 'white', color: 'black', border: '1px solid #ced4da', textAlign: 'justify' }} 
        disabled={disabled}
        >
        {selectedOption ? selectedOption[displayKey] : placeholder}
      </Dropdown.Toggle>

      {!disabled && (
        <Dropdown.Menu className="w-100" style={{ backgroundColor: 'white', maxHeight: '200px', overflowY: 'auto', width: '100%' }}>
          <FormControl 
            autoFocus 
            className="mx-3 my-2 sticky-top searchabletextbox" 
            placeholder="Search..." 
            onChange={handleSearchChange} 
            value={searchTerm}
            disabled={disabled}
          />
          <div style={{ maxHeight: '150px' }}>
            {filteredOptions.map((option) => (
              <Dropdown.Item
                key={option[valueKey]}
                onClick={() => handleSelect(option[valueKey])}
                style={{ backgroundColor: 'white', color: 'black' }}
              >
                {option[displayKey]}
              </Dropdown.Item>
            ))}
          </div>
        </Dropdown.Menu>
      )}
    </Dropdown>
  );
};

export default SearchableDropdown;