import React from 'react';
import './dashboard.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Header from '../../assets/header/Header.js';
import Sidebar from '../../assets/sidebar/sidebar.js';

const Dashboard = () => {
   return (
        <>
            <Header />
            <div className="d-flex">
                <Sidebar />
                <div className="main bg-light-iom"> 
                    <div className='dashboard-class'>
                        <div className='cont-wrapper'>
                            <h4>Request Detailed Status</h4>
                            <div className='row'>
                                <div className="col-lg-4 col-md-4 col-sm-6 col-12 mb-2 mt-4">
                                    <div className="inforide">
                                        <div className="row">
                                            <div className="col-lg-9 col-md-8 col-sm-8 col-8 fontsty">
                                                <h4>Draft Requests</h4>
                                                <h2>20</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            <div className="col-lg-4 col-md-4 col-sm-6 col-12 mb-2 mt-4">
                                <div className="inforide">
                                    <div className="row">
                                        <div className="col-lg-9 col-md-8 col-sm-8 col-8 fontsty">
                                            <h4>Submitted Requests</h4>
                                            <h2>120</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-6 col-12 mb-2 mt-4">
                                <div className="inforide">
                                    <div className="row">
                                        <div className="col-lg-9 col-md-8 col-sm-8 col-8 fontsty">
                                            <h4>Approved Requests</h4>
                                            <h2>50</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        </>
    );
};
export default Dashboard;
