import React, { useState } from 'react';
import { useModal } from '../modalPopup/modelcontext';
import 'bootstrap/dist/css/bootstrap.min.css';
import { UpdatePasswordService, HashedPassword } from '../../services/loginService';
import Header from '../../assets/header/Header.js';
import Sidebar from '../../assets/sidebar/sidebar.js';

function ChangePasswordPage() {
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const { showModal } = useModal();
    const [errors, setErrors] = useState({});

    const validate = () => {
        const newErrors = {};
        if (!oldPassword) newErrors.oldPassword = 'Old Password is required.';
        if (!newPassword) newErrors.newPassword = 'New Password is required.';
        if (!confirmPassword) newErrors.confirmPassword = 'Confirm Password is required.';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handlePasswordReset = async () => {
        try {
            if (!validate()) {
                return;
              }
            if (newPassword !== confirmPassword) {
            showModal('failure', 'New Password and Confirm Password do not match!');
            return;
            }
            const hashedOldPassword = HashedPassword(oldPassword);
            const hashedNewPassword = HashedPassword(newPassword);
            const payload = { oldPassword: hashedOldPassword, newPassword:hashedNewPassword};
        
            UpdatePasswordService(payload).then((res) => {
                if (res.data.success) {
                showModal('success', res.data.message);
                handleCancelClick();
                } else {
                showModal('failure', res.data.message);
                }
            });
        } catch (error) {
          showModal('failure', 'Error Occurred while resetting the password, please try again later.');
        }
    };

    const handleCancelClick = () => {
        setErrors({});
        setOldPassword('');
        setNewPassword('');
        setConfirmPassword('');
    };

    return (
        <>
            <Header />
            <div className="d-flex">
                <Sidebar />
                <div className="main bg-light-iom">
                    <div className='dashboard-class'>
                        <div className='cont-wrapper'>
                            <div className="container mt-5">
                                <div className="mb-3">
                                    <div className='row'>
                                        <div className="col-md-6">
                                            <h4>Change Password</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="card mb-3">
                                    <div className='card-body'>
                                        <div className='row'>
                                            <div className="form-group mb-3 col-md-6">
                                                <label htmlFor="oldPassword" className="form-label">Old Password: <span className="text-danger">*</span></label>
                                                <input type="password" className="form-control" id="oldPassword" placeholder="Enter your password" value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} required />
                                                {errors.oldPassword && <p style={{color: 'red'}}>{errors.oldPassword}</p>}
                                            </div>
                                            <div className="form-group mb-3 col-md-6">
                                                <label htmlFor="newPassword" className="form-label">New Password: <span className="text-danger">*</span></label>
                                                <input type="password" className="form-control" id="newPassword" placeholder="Enter your password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} required />
                                                {errors.newPassword && <p style={{color: 'red'}}>{errors.newPassword}</p>}
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="form-group mb-3 col-md-6">
                                                <label htmlFor="confirmPassword" className="form-label">Confirm Password: <span className="text-danger">*</span></label>
                                                <input type="password" className="form-control" id="confirmPassword" placeholder="Enter your password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required />
                                                {errors.confirmPassword && <p style={{color: 'red'}}>{errors.confirmPassword}</p>}
                                            </div>
                                            <div className="form-group mb-3 col-md-6" style={{marginTop:'32px'}}>
                                                <button type="submit" id="btnSave" className="btn btn-primary me-2 mb-2" onClick={handlePasswordReset}>Submit</button> 
                                                <button type="button" className="btn btn-danger mb-2" onClick={handleCancelClick}>Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ChangePasswordPage;
