import React from 'react';
import { components } from 'react-select';

export const CustomOption = (props) => {
    const { data, isSelected, innerRef, innerProps } = props;
    return (
        <components.Option {...props} ref={innerRef} {...innerProps}>
            <input
                type="checkbox"
                checked={isSelected}
                readOnly
                style={{ marginRight: 10 }}
            />
            <label>{data.label}</label>
        </components.Option>
    );
};

export const CustomSingleValue = (props) => {
    return (
        <components.SingleValue {...props}>
            {props.data.label}
        </components.SingleValue>
    );
};