import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useModal } from '../modalPopup/modelcontext';
import 'bootstrap/dist/css/bootstrap.min.css';
import './login.css';
import { ResetPasswordService, HashedPassword } from '../../services/loginService';

function ResetPasswordPage() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search); 
    const token = queryParams.get('token'); 
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const { showModal } = useModal();
    const navigate = useNavigate();

    const handlePasswordReset = async () => {
        try {
          if (password !== confirmPassword) {
            showModal('failure', 'New Password and Confirm Password do not match!');
            return;
          }
          const hashedPassword = HashedPassword(password);
          const payload = { token, newPassword:hashedPassword};
      
          ResetPasswordService(payload).then((res) => {
            if (res.data.success) {
              showModal('success', res.data.message);
              navigate('/login', { replace: true });
            } else {
              showModal('failure', res.data.message);
            }
          });
        } catch (error) {
          showModal('failure', 'Error Occurred while resetting the password, please try again later.');
        }
    };

  return (
    <div className='login-wrapper'>
        <div className='row justify-content-center'>
            <div className="col-lg-6 col-md-6 col-sm-12 login-info d-flex flex-column justify-content-center text-center">
                <div className="mb-4">
                    <Link to='/' className="navbar-brand">
                    <img src="/IOM-logo-white.png" alt="IOM Logo" className="img-fluid" style={{maxWidth: "150px"}} />
                    </Link>
                </div>
                <h1 className="text-white">Welcome to IOM Surge Deployments</h1>
                <p className="text-light mt-3">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                </p>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 login-form">
                <div className="card shadow-lg">
                    <div className="card-body">
                        <h4 className="text-center mb-4">Reset Password</h4>
                        <form>
                            <div className="form-group mb-3">
                                <label htmlFor="newPassword" className="form-label">New Password: <span className="text-danger">*</span></label>
                                <input type="password" className="form-control" id="newPassword" placeholder="Enter your password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                            </div>
                            <div className="form-group mb-3">
                                <label htmlFor="confirmPassword" className="form-label">Confirm Password: <span className="text-danger">*</span></label>
                                <input type="password" className="form-control" id="confirmPassword" placeholder="Enter your password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required />
                            </div>
                            <button className="btn btn-primary btn-block w-100" type="button" onClick={handlePasswordReset}>
                            Reset Password
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
);
}

export default ResetPasswordPage;
