import React from 'react';
import './header.css';
import { Link } from "react-router-dom";
import Dashboard from '../../components/dashboard/dashboard';

function Header() {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  return (
    <header className="header">
       <div className="">
            <div className='row w-100 align-items-center'>
              <div className="col-md-2 text-start">
                <Link to={Dashboard} className="navbar-brand mr-0 mr-md-2">
                <img src="/IOM-logo-white.png" alt="Logo" />
                </Link>
              </div>
              <div className="col-md-8 header-text">
                <h1>Surge Deployments</h1>
              </div>
              <div className="col-md-2 user-login text-end">
              {userInfo ? (
                  <p><span>{userInfo.userName}</span> <span>({userInfo.email})</span></p>
                ) : (
                  <p>Not logged in</p>
                )}
              </div>
            </div>
        </div>
    </header>
  );
}

export default Header;