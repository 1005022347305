export const config = {
    headers: {
      'Content-Type': 'application/json',
    },
    postHeaders: {
      'Content-Type': 'multipart/form-data'
    },
    apiUrl : process.env.REACT_APP_API_URL,
    passwordRegex : /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
    passwordLength : 6,
    passwordSecretKey : process.env.REACT_APP_PASSWORD_SECRET_KEY,
    ivstring : process.env.REACT_APP_IV_STRING,
    securityCodePolicies: [
      "1. Password must be at least 6 characters long.",
      "2. Password must include at least one lowercase letter.",
      "3. Password must include at least one uppercase letter.",
      "4. Password must include at least one number.",
      "5. Password must include at least one special character." 
    ],
    emailRegex : /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    lockAttemptCount : 3,
    applicationName : process.env.REACT_APP_APPLICATION_NAME,
    applicationTitle : process.env.REACT_APP_APPLICATION_TITLE
  }; 

