import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useModal } from '../modalPopup/modelcontext';
import 'bootstrap/dist/css/bootstrap.min.css';
import './login.css';
import { ForgotPassword as ForgotPasswordService } from '../../services/loginService';

function ForgotPasswordPage() {
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const { showModal } = useModal();

    const handlePasswordReset = async () => {
        try {
          if (!email) {
            showModal('failure', 'Please enter a valid email address.');
            return;
          }
          const inValidEmail = /^[a-zA-Z0-9._%+-]+@iom\.int$/.test(email);        
          if (inValidEmail) {
              showModal('failure', 'You can not rest password for IOM Account Account');
              return;
          }
          setLoading(true); 
          var resetLink = `${window.location.origin}/resetpassword`;
          const payload = { email };
      
          ForgotPasswordService(payload, resetLink).then((res) => {
            if (res.data.success) {
              showModal('success', res.data.message);
              setEmail('');
            } else {
              showModal('failure', res.data.message);
            }
            setLoading(false);
          });
        } catch (error) {
          setLoading(false); 
          showModal('failure', 'Error sending password reset email, please try again later.');
        }
    };

  return (
    <div className='login-wrapper'>
        <div className='row justify-content-center'>
            <div className="col-lg-6 col-md-6 col-sm-12 login-info d-flex flex-column justify-content-center text-center">
            <div className="mb-4">
                <Link to='/' className="navbar-brand">
                <img src="/IOM-logo-white.png" alt="IOM Logo" className="img-fluid" style={{maxWidth: "150px"}} />
                </Link>
            </div>
            <h1 className="text-white">Welcome to IOM Surge Deployments</h1>
            <p className="text-light mt-3">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
            </p>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 login-form">
            <div className="card shadow-lg">
                <div className="card-body">
                <h4 className="text-center mb-4">Forgot Password</h4>
                <form>
                    <div className="form-group mb-3">
                    <label htmlFor="email" className="form-label">Username <span className="text-danger">*</span></label>
                    <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    </div>
                    <button className="btn btn-primary btn-block w-100" type="button" onClick={handlePasswordReset} disabled={loading ? true : false}>
                    {loading ? 'Processing...' : 'Send Reset Link'}
                    </button>
                </form>

                <div className="text-center mt-3">
                    <small>Remember your password? <Link to="/Login">Login</Link></small>
                </div>
                </div>
            </div>
            </div>
        </div>
    </div>
);
}

export default ForgotPasswordPage;
