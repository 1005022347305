import React, { useState, useEffect } from 'react';
import { HashedPassword, signUp } from '../../services/loginService';
import axios from 'axios';
import { config } from '../../constants/constant';
import { useModal } from '../modalPopup/modelcontext';
import 'bootstrap/dist/css/bootstrap.min.css';
import './login.css';
import { Link,useNavigate } from 'react-router-dom';
import SearchableDropdown from './searchableDropdown'
import { useMsal } from "@azure/msal-react";
import { loginRequest } from '../../services/ssoAuthConfig';
import { InteractionStatus } from "@azure/msal-browser";
import msalInstance from '../../services/ssoAuthConfig';

function SignUp() {
  const { inProgress } = useMsal();
  const [authInProgress, setAuthInProgress] = useState(false);
  const [authSuccessful, setAuthSuccessful] = useState(false);
  const [selectedOption, setSelectedOption] = useState('IOM Account');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [securityCode, setSecurityCode] = useState('');
  const [email, setEmail] = useState('');
  const [jobtitle, setJobtitle] = useState('');
  const [selectedMission, setSelectedMission] = useState('');
  const [otherDetails, setOtherdetails] = useState('');
  const [adFirstname, setAdFirstname] = useState('');
  const [adLastname, setAdLastname] = useState('');
  const [adEmail, setAdEmail] = useState('');
  const [adJobtitle, setAdJobtitle] = useState('');
  const [adSelectedMission, setAdSelectedMission] = useState('');
  const [adOtherDetails, setAdOtherdetails] = useState('');
  const [errors, setErrors] = useState({});
  const { showModal } = useModal();
  const [options, setOptions] = useState([]);
  const navigate = useNavigate();

  const clearControls =() =>{
    setSelectedOption('');
    setFirstname('');
    setLastname('');
    setEmail('');
    setSecurityCode('');
    setJobtitle('');
    setSelectedMission('');
    setOtherdetails('');
    setAdFirstname('');
    setAdLastname('');
    setAdEmail('');
    setAdJobtitle('');
    setAdSelectedMission('');
    setAdOtherdetails('');
  }

  useEffect(() => {
    const allofficess = async () => {
      try {
        const response = await axios.get(config.apiUrl + '/api/Account/alloffices');
        setOptions(response.data); 
      } catch (error) {
        console.error('Error fetching dropdown options:', error);
      }
    };
    allofficess();
    if (inProgress === InteractionStatus.None && authInProgress) {
      setAuthInProgress(false);
    }
  }, [inProgress,authInProgress]);

  const validate = () => {
    const newErrors = {};
    if (!selectedOption) newErrors.selectedOption = 'Please select an account type.';
    if (selectedOption === 'Personal Account') {
      if (!firstname) newErrors.firstname = 'First name is required.';
      if (!lastname) newErrors.lastname = 'Last name is required.';
      if (!email) newErrors.email = 'Email is required.';
      else if (!config.emailRegex.test(email)) newErrors.email = 'Please enter a valid email address.';
      if (!securityCode) newErrors.securityCode = 'Password is required.';
      else if (securityCode.length < config.passwordLength) newErrors.securityCode = 'Password should not be less than 6 characters';
      else if(!config.passwordRegex.test(securityCode)) newErrors.securityCode = 'Password must include at least one lowercase letter, one uppercase letter, one number, and one special character.';
      if (!jobtitle) newErrors.jobtitle = 'Job title is required.';
      if (!selectedMission) newErrors.selectedMission = 'Please select a mission.';
    }
    if (authSuccessful) {
      if (!adJobtitle) newErrors.adJobtitle = 'Job title is required.';
      if (!adSelectedMission) newErrors.adSelectedMission = 'Please select a mission.';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e, setState, fieldName) => {
    setState(e.target.value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: '',
    }));
  };

  const handleSignup = async () => {
    try {
      if(selectedOption === 'Personal Account'){
        const inValidEmail = /^[a-zA-Z0-9._%+-]+@iom\.int$/.test(email);        
          if (inValidEmail) {
              showModal('failure', 'You can not create account Personal Account Account with domain @iom.int, Use IOM Account for Account Creation');
              return;
          }
      }else if (!validate()) {
        return;
      }
      var payload = {};
      if (selectedOption === 'IOM Account') {
              if(authSuccessful){
                const adOfficeDetails = options.find(item => item.officeId === adSelectedMission);
                payload = {accountType:selectedOption, firstName: adFirstname, lastName:adLastname, emailId : adEmail, securityCode: '', positionTitle : adJobtitle, officeID:adSelectedMission, otherDetails:adOtherDetails, officeName: adOfficeDetails.officeDescription };
                RegistraionProcess(payload);       
              }
              else {
                    if (!authInProgress) {
                      setAuthInProgress(true);
                      try {
                        const response=  await msalInstance.loginPopup(loginRequest);
                        if(response !== null){
                          setAuthSuccessful(true);
                          if (response.account.name.includes(' ')) {
                              const [first, ...rest] = response.account.name.split(' ');
                              if(response.account.username.split('@')[1] === 'iom.int'){
                                setAdLastname(first);
                                setAdFirstname(rest.join(' '));
                              }
                              else{
                                setAdFirstname(first);
                                setAdLastname(rest.join(' '));
                              }
                          }
                          else{
                              setAdFirstname(response.account.name);
                              setAdLastname('');
                          }
                          setAdEmail(response.account.username);
                        }
                      } catch (error) {
                        setAuthInProgress(false);
                        showModal('failure', 'AD authentication failed with error '+ error);
                      }
                    } else {
                      showModal('failure','Authentication in progress');
                    }
              }          
      } 
      else {
            const hashedPassword = HashedPassword(securityCode);
            setSecurityCode(hashedPassword);
            const personalOfficeDetails = options.find(item => item.officeId === selectedMission); 
            payload = {accountType:selectedOption, firstName: firstname, lastName:lastname, emailId : email, securityCode: hashedPassword, positionTitle : jobtitle, officeID:selectedMission, otherDetails:otherDetails, officeName : personalOfficeDetails.officeDescription };
            RegistraionProcess(payload);      
      }
    } 
    catch (error) {
      showModal('failure','Registration failed! Please try again later.');
    }

    function RegistraionProcess(payload) {
      signUp(payload).then((res) => {
        if (res.data.success) {
          clearControls();
          showModal('success', res.data.message);
          navigate('/login', { replace: true });
        } else {
          showModal('failure', res.data.message);
        }
      });
    }
  };

  return (
    <div className='login-wrapper'>
    <div className='row'>
      <div className="col-md-6 login-info">
        <div className="mb-5">
          <Link to='/' className="navbar-brand">
            <img src="/IOM-logo-white.png" alt="Logo" />
          </Link>
        </div>
        <h1>Welcome to IOM Surge Deployments</h1>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit,
          sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua. Ut enim ad minim veniam, quis nostrud
          exercitation ullamco laboris nisi ut aliquip ex ea
          commodo consequat.
        </p>
      </div>
      <div className="col-md-6 login-form">
        <h6 className="text-center">Create New account</h6>
            <div className="card">
              <div className="card-body">
                <div className='row'>
                  <div className="form-group mb-3">
                    <label htmlFor="authType" className="form-label">Select Account Type <span style={{color:"red", font:"bold"}}>*</span></label>
                    <select id="authType" className="form-select" value={selectedOption} onChange={(e) => handleInputChange(e, setSelectedOption, 'selectedOption')}>
                      <option value="">--Select--</option>
                      <option value="IOM Account">IOM Account</option>
                      <option value="Personal Account">Personal Account</option>
                    </select>
                    {errors.selectedOption && <p style={{color: 'red'}}>{errors.selectedOption}</p>}
                  </div>
                </div>
                  {selectedOption === 'Personal Account' && (
                    <><div className='row'>
                      <div className="form-group mb-3 col-md-6">
                        <label htmlFor="firstname" className="form-label">First Name <span style={{color:"red", font:"bold"}}>*</span></label>
                        <input type="text" id="firstname" className="form-control" value={firstname} onChange={(e) => handleInputChange(e, setFirstname, 'firstname')}/>
                        {errors.firstname && <p style={{color: 'red'}}>{errors.firstname}</p>}
                      </div>
                      <div className="form-group mb-3 col-md-6">
                        <label htmlFor="lastname" className="form-label">Last Name <span style={{color:"red", font:"bold"}}>*</span></label>
                        <input type="text" id="lastname" className="form-control" value={lastname}  onChange={(e) => handleInputChange(e, setLastname, 'lastname')}/>
                        {errors.lastname && <p style={{color: 'red'}}>{errors.lastname}</p>}
                      </div>
                      </div>
                      <div className='row'>
                      <div className="form-group mb-3 col-md-6">
                        <label htmlFor="email" className="form-label">Email <span style={{color:"red", font:"bold"}}>*</span></label>
                        <input type="text" id="email" className="form-control" value={email} onChange={(e) => handleInputChange(e, setEmail, 'email')}/>
                        {errors.email && <p style={{color: 'red'}}>{errors.email}</p>}
                      </div>
                      <div className="form-group mb-3 col-md-6">
                        <label htmlFor="securityCode" className="form-label">Password <span style={{color:"red", font:"bold"}}>*</span></label><span><i className="bi bi-info-circle" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-html="true" title={config.securityCodePolicies}/></span>
                        <input type="password" id="securityCode" className="form-control" value={securityCode} onChange={(e) => handleInputChange(e, setSecurityCode, 'securityCode')}/>
                        {errors.securityCode && <p style={{color: 'red'}}>{errors.securityCode}</p>}
                      </div>
                      </div>
                      <div className='row'>
                        <div className="form-group mb-3">
                          <label htmlFor="jobtitle" className="form-label">Job Title <span style={{color:"red", font:"bold"}}>*</span></label>
                          <input type="text" id="jobtitle" className="form-control" value={jobtitle} onChange={(e) => handleInputChange(e, setJobtitle, 'jobtitle')}/>
                          {errors.jobtitle && <p style={{color: 'red'}}>{errors.jobtitle}</p>}
                        </div>
                      </div>
                      <div className='row'>
                        <div className="form-group mb-3">
                          <label htmlFor="mission" className="form-label">Mission <span style={{color:"red", font:"bold"}}>*</span></label>
                            <SearchableDropdown options={options} selectedValue={selectedMission} onChange={(value) => handleInputChange({ target: { value } }, setSelectedMission, 'selectedMission')} displayKey="officeDescription" valueKey="officeId" className="form-select" disabled={false}/>
                          {errors.selectedMission && <p style={{color: 'red'}}>{errors.selectedMission}</p>}
                        </div>
                      </div>
                      <div className='row'>
                        <div className="form-group mb-3">
                          <label htmlFor="otherDetails" className="form-label">Other Details:</label>
                          <input type="text" id="otherDetails" className="form-control" value={otherDetails} onChange={(e) => setOtherdetails(e.target.value)}/>
                          <p style={{color: 'darkorange'}}>Please provide comprehensive details on the User Request, including the required roles and office information. This will help the administrator accurately understand the request and appropriately assign the roles.</p>
                        </div>
                      </div>
                    </>
                  )}
                  {authSuccessful &&(
                    <><div className='row'>
                    <div className="form-group mb-3 col-md-6">
                      <label htmlFor="adfirstname" className="form-label">First Name <span style={{color:"red", font:"bold"}}>*</span></label>
                      <input type="text" id="adfirstname" className="form-control" value={adFirstname} disabled/>
                      {errors.adFirstname && <p style={{color: 'red'}}>{errors.adFirstname}</p>}
                    </div>
                    <div className="form-group mb-3 col-md-6">
                      <label htmlFor="adlastname" className="form-label">Last Name <span style={{color:"red", font:"bold"}}>*</span></label>
                      <input type="text" id="adlastname" className="form-control" value={adLastname} disabled/>
                      {errors.adLastname && <p style={{color: 'red'}}>{errors.adLastname}</p>}
                    </div>
                    </div>
                    <div className='row'>
                    <div className="form-group mb-3 col-md-6">
                      <label htmlFor="ademail" className="form-label">Email <span style={{color:"red", font:"bold"}}>*</span></label>
                      <input type="text" id="ademail" className="form-control" value={adEmail} disabled/>
                      {errors.adEmail && <p style={{color: 'red'}}>{errors.adEmail}</p>}
                    </div>
                    <div className="form-group mb-3 col-md-6">
                        <label htmlFor="adjobtitle" className="form-label">Job Title <span style={{color:"red", font:"bold"}}>*</span></label>
                        <input type="text" id="adjobtitle" className="form-control" value={adJobtitle} onChange={(e) => handleInputChange(e, setAdJobtitle, 'adJobtitle')}/>
                        {errors.adJobtitle && <p style={{color: 'red'}}>{errors.adJobtitle}</p>}
                      </div>
                    </div>
                     <div className='row'>
                      <div className="form-group mb-3">
                        <label htmlFor="admission" className="form-label">Mission <span style={{color:"red", font:"bold"}}>*</span></label>
                          <SearchableDropdown options={options} selectedValue={adSelectedMission} onChange={(value) => handleInputChange({ target: { value } }, setAdSelectedMission, 'adSelectedMission')} displayKey="officeDescription" valueKey="officeId" className="form-select" disabled={false}/>
                        {errors.adSelectedMission && <p style={{color: 'red'}}>{errors.adSelectedMission}</p>}
                      </div>
                    </div>
                    <div className='row'>
                      <div className="form-group mb-3">
                        <label htmlFor="adotherDetails" className="form-label">Other Details:</label>
                        <input type="text" id="adotherDetails" className="form-control" value={adOtherDetails} onChange={(e) => setAdOtherdetails(e.target.value)}/>
                        <p style={{color: 'darkorange'}}>Please provide comprehensive details on the User Request, including the required roles and office information. This will help the administrator accurately understand the request and appropriately assign the roles.</p>
                      </div>
                    </div>
                  </>
                  )}
                  <div className='row'>
                    <div className='form-group mb-3'>
                      <button className="btn btn-primary w-100" onClick={handleSignup}>Sign up</button>
                      <div className="mt-3 d-flex justify-content-between">
                        <div>Already have Account? Click here to Login <Link to="/Login"> Login</Link></div>
                     </div>
                    </div>                 
                  </div>
                </div>
              </div>       
            </div>
          </div>
        </div>
     );
}

export default SignUp;
