import React, { useState, useEffect } from 'react';
import Header from '../../assets/header/Header.js';
import Sidebar from '../../assets/sidebar/sidebar.js';
import { AddWbsDetails, GetAllWBSDetails } from '../../services/adminServices.js';
import { useModal } from '../modalPopup/modelcontext.js';
import { GetAllDeploymentTypes } from '../../services/dropdownServices.js';

const WBSDetails = () => {
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [selectedSupportType, setSelectedSupportType] = useState(null);
    const [wbsCode, setWbsCode] = useState('');
    const [wbsDescription, setWbsDescription] = useState('');
    const [wbsStatus, setWbsStatus] = useState(true);
    const [errors, setErrors] = useState({});
    const { showModal } = useModal();
    const [allWbsDetails, setAllWbsDetails] = useState([]);
    const [allSupportTypes, setAllSupportTypes] = useState([]);
    const [wbsDetailId, setwbsDetailId] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState('');   
    const [btnSaveText, setBtnSaveText] = useState('');
    const [isEditClicked, setIsEditClicked] = useState(false);

    const handleAddWBSClick = () => {
        setIsFormVisible(true);
        setIsEditClicked(false);
        setBtnSaveText('Add WBS');
      };

    const handleCancelClick = () => {
        setIsFormVisible(false);
        setErrors({});
        setSelectedSupportType(null);
        setWbsCode(null);
        setWbsDescription(null);
        setWbsStatus(null);
    };

    const fetchSupportTypes = async() => {
        try{
            GetAllDeploymentTypes().then((response) =>{
                if(response.status === 200){
                    setAllSupportTypes(response.data);
                }
            });
        }catch(error){
            console.error('Error while fetching Support types dropdownlist details:', error);
        }
    };

    const fetchWBSDetails = async() => {
        try{
            GetAllWBSDetails().then((response) =>{
                if(response.status === 200){
                    setAllWbsDetails(response.data);
                }
            });
        }catch(error){
            console.error('Error while fetching wbs details:', error);
        }
    };

    useEffect(() => { 
        fetchSupportTypes();
        fetchWBSDetails();        
    },[]);
 
    // Function to calculate pagination and filter data
    const filteredWbs = allWbsDetails.filter((wbs) =>(
        (wbs.wbsCode && wbs.wbsCode.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (wbs.wbsDescription && wbs.wbsDescription.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (wbs.supportTypeName && wbs.supportTypeName.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (wbs.WbsStatusName && wbs.WbsStatusName.toLowerCase().includes(searchQuery.toLowerCase())))
    );

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredWbs.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredWbs.length / itemsPerPage);
    const goToPage = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const startRecord = indexOfFirstItem + 1;
    const endRecord = Math.min(indexOfLastItem, filteredWbs.length);
    //End Pagination logic here 

    const handleInputChange = (e, setState, fieldName) => {
        setState(e.target.value);
        if (fieldName === 'wbsCode') {
            const isDuplicate = allWbsDetails.some(item =>
              item.wbsCode === e.target.value
            );        
            if (isDuplicate) {
              setErrors((prevErrors) => ({
                ...prevErrors,
                [fieldName]: 'The WBS Code should be Unique',
              }));
            }
            else{
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [fieldName]: '',
                  }));
            }
        }else{
            setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: '',}));
        }        
      };

    const validate = () => {
        const newErrors = {};
        if (!wbsCode) newErrors.wbsCode = 'WBS Code is required.';
        if (!wbsDescription) newErrors.wbsDescription = 'WBS Description is required.';
        if (!selectedSupportType) newErrors.selectedSupportType = 'Support Type is required.';
        if(wbsCode && wbsDetailId===0){
            const isDuplicate = allWbsDetails.some(item =>
                item.wbsCode === wbsCode
            ); 
            if (isDuplicate) {newErrors.wbsCode = 'WBS Code should be unique'; }
        }
        if(wbsCode && wbsDetailId!==0){
            const isDuplicate = allWbsDetails.some(item =>
                item.wbsCode === wbsCode && item.wbsDetailId !== wbsDetailId
            ); 
            if (isDuplicate) {newErrors.wbsCode = 'WBS Code should be unique'; }
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const clearControls=()=>{
        setWbsCode('');
        setWbsDescription('');
        setWbsStatus(false);
        setSelectedSupportType('');
        setwbsDetailId(0);
        setErrors({});
    }

    const saveWBSData = async () =>{
        try {
            if (!validate()) {
              return;
            }
              var payload = {wbsDetailId, wbsCode, wbsDescription, wbsSupportType:parseInt(selectedSupportType), wbsStatus};
              AddWbsDetails(payload).then((res) => {
               if (res.data.success) {
                showModal('success',res.data.message);
                setIsFormVisible(false);
                clearControls();
                fetchWBSDetails();
              } else {
                showModal('failure',res.data.message);
              }       
            });
          } 
          catch (error) {
            showModal('failure','Adding New WBS failed, Please try again later.');
          }
    };

    const handleCheckboxChange = (e) => {
        setWbsStatus(e.target.checked);
    };

    const handleUpdateClick = (wbs) => {
        setIsFormVisible(true);
        setIsEditClicked(true);
        setBtnSaveText('Update WBS');
        setwbsDetailId(wbs.wbsDetailId)
        setWbsCode(wbs.wbsCode);
        setWbsDescription(wbs.wbsDescription);
        setWbsStatus(wbs.wbsStatus);
        setSelectedSupportType(wbs.wbsSupportType);
    };

    return (
        <>
            <Header />
            <div className="d-flex">
                <Sidebar />
                <div className="main bg-light-iom">
                    <div className='dashboard-class'>
                        <div className='cont-wrapper'>
                            <div className="container mt-5">
                                <div className="mb-3">
                                    <div className='row'>
                                        <div className="col-md-6">
                                            <h4>WBS Details</h4>
                                        </div>
                                        <div className="col-md-6 text-md-right" style={{textAlign:'right'}}>
                                            <button className="btn btn-primary" onClick={handleAddWBSClick}>Add WBS</button>
                                        </div>
                                    </div>
                                </div>
                                {isFormVisible && (
                                <div className="card mb-3">
                                    <div className="card-header">
                                    <h6>WBS Information</h6>
                                    </div>
                                    <div className='card-body'>
                                        <div className='row'>
                                        <div className="form-group mb-3 col-md-4">
                                                <label htmlFor="wbscode" className="form-label">WBS Code <span style={{color:"red", font:"bold"}}>*</span></label>
                                                <input type="text" className="form-control" id="wbscode" placeholder="WBS Code" value={wbsCode} onChange={(e) => handleInputChange(e, setWbsCode, 'wbsCode')}/>
                                                {errors.wbsCode && <p style={{color: 'red'}}>{errors.wbsCode}</p>}
                                            </div>    
                                            <div className="form-group mb-3 col-md-4">
                                                <label htmlFor="wbsdescription" className='form-label'>WBS Description <span style={{color:"red", font:"bold"}}>*</span></label>
                                                <input type="text" className="form-control" id="wbsdescription" placeholder="WBS Description" value={wbsDescription} onChange={(e) => handleInputChange(e, setWbsDescription, 'wbsDescription')}/>
                                                {errors.wbsDescription && <p style={{color: 'red'}}>{errors.wbsDescription}</p>}
                                            </div>                                        
                                            <div className="form-group mb-3 col-md-4">
                                                <label htmlFor="supportType" className='form-label'>Support Type <span style={{color:"red", font:"bold"}}>*</span></label>
                                                <select id="supportType" className="form-select" value={selectedSupportType} onChange={(e) => handleInputChange(e, setSelectedSupportType, 'selectedSupportType')}>
                                                        <option value={''}>--Select--</option>
                                                        {allSupportTypes.map((st) => (
                                                            <option key={st.supportTypeId} value={st.supportTypeId}>{st.supportTypeName}</option>
                                                        ))}
                                                </select>
                                                {errors.selectedSupportType && <p style={{color: 'red'}}>{errors.selectedSupportType}</p>}
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="form-group col-md-2">
                                                <input id='wbsstatus' type='checkbox' className='me-2 mb-2' checked={wbsStatus} onChange={handleCheckboxChange}></input>
                                                <label htmlFor="wbsstatus" className='form-label'>Is Active </label>
                                                {errors.wbsStatus && <p style={{color: 'red'}}>{errors.wbsStatus}</p>}
                                            </div>
                                            <div className="form-group col-md-10" style={{textAlign:'right'}}>
                                                <button type="submit" id="btnSave" className="btn btn-primary me-2" onClick={saveWBSData}>{btnSaveText}</button> 
                                                {!isEditClicked && (
                                                    <button type="reset" className="btn btn-secondary me-2" onClick={clearControls}>Clear Form</button>
                                                ) }                                                
                                                <button type="button" className="btn btn-danger" onClick={handleCancelClick}>Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                )}                              
                                <div id="teamdetailsList" className='card mb-3'>
                                    <div className="card-header">
                                        <h6>WBS List</h6>
                                    </div>
                                        <div className='card-body'>
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                            <div>
                                                Show{' '}
                                                    <select value={itemsPerPage} onChange={(e) => setItemsPerPage(Number(e.target.value))} className="form-select d-inline-block w-auto">
                                                        <option value={5}>5</option>
                                                        <option value={10}>10</option>
                                                        <option value={20}>20</option>
                                                        <option value={50}>50</option>
                                                    </select>{' '}
                                                entries
                                            </div>
                                            <div className="w-25">
                                                <input type="text" className="form-control" placeholder="Search..." value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
                                            </div>
                                        </div>          
                                        <div className="table-responsive" style={{fontSize:"0.9rem"}}>
                                            <table className="table table-bordered table-striped">
                                                <thead className="thead-light" style={{background:"ghostwhite"}}>
                                                    <tr>
                                                        <th style={{background:"#7a9fd9", textAlign: 'center', verticalAlign: 'middle'}}>WBS Code</th>
                                                        <th style={{background:"#7a9fd9", textAlign: 'center', verticalAlign: 'middle'}}>WBS Description</th>
                                                        <th style={{background:"#7a9fd9", textAlign: 'center', verticalAlign: 'middle'}}>Support Type</th>
                                                        <th style={{background:"#7a9fd9", textAlign: 'center', verticalAlign: 'middle'}}>WBS Status</th>
                                                        <th style={{background:"#7a9fd9", textAlign: 'center', verticalAlign: 'middle'}}>Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {currentItems.map((wbs) => (
                                                    <tr key={wbs.wbsDetailId}>
                                                        <td>{wbs.wbsCode}</td>
                                                        <td>{wbs.wbsDescription}</td>
                                                        <td>{wbs.supportTypeName}</td>
                                                        <td>{wbs.wbsStatusName}</td>
                                                        <td style={{textAlign: 'center'}}>
                                                            <button className="btn btn-default btn-sm" onClick={() => handleUpdateClick(wbs)}>
                                                            <span className='bi bi-pencil' style={{color:'#ffc107'}}/>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <span>
                                                Showing {startRecord} to {endRecord} of {allWbsDetails.length} records
                                            </span>
                                            <nav aria-label="Page navigation example">
                                                <ul className="pagination justify-content-end">
                                                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                        <button className="page-link" onClick={() => goToPage(currentPage - 1)}><span className='pi pi-perviousbi bi-skip-backward-fill'></span></button>
                                                    </li>
                                                    {Array.from({ length: totalPages }, (_, index) => (
                                                    <li key={index + 1} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                                                        <button className="page-link" onClick={() => goToPage(index + 1)}>{index + 1}</button>
                                                    </li>
                                                    ))}
                                                    <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                                        <button className="page-link" onClick={() => goToPage(currentPage + 1)}><span className='bi bi-skip-forward-fill'/></button>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default WBSDetails;
