import React, { useState, useEffect } from 'react';
import { Link,useNavigate } from 'react-router-dom';
import { Signin,HashedPassword, LockAccount} from '../../services/loginService'
import { useModal } from '../modalPopup/modelcontext';
import 'bootstrap/dist/css/bootstrap.min.css';
import './login.css';
import { config } from '../../constants/constant';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from '../../services/ssoAuthConfig';
import { InteractionStatus } from "@azure/msal-browser";
import {useAuth} from '../../services/authContext';
import msalInstance from '../../services/ssoAuthConfig';

function Login() {
  const { inProgress } = useMsal();
  const [authInProgress, setAuthInProgress] = useState(false);
  const [selectedOption, setSelectedOption] = useState('IOM Account');
  const [forgotPasswordLink, setForgotPasswordLink] = useState(false);
  const [username, setUsername] = useState('');
  const [securityCode, setSecurityCode] = useState('');
  const { showModal } = useModal();
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const { loginsuccess } = useAuth();

  const validateFields = () => {
    const newErrors = {};
    if (!selectedOption) newErrors.selectedOption = 'Account Type is required';
    if (selectedOption === 'Personal Account'){
      if (!username) newErrors.username ='User name is required';
      if (!securityCode) newErrors.securityCode = 'Password is required';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e, setState, fieldName) => {
    setState(e.target.value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: '',
    }));
    if(selectedOption === 'IOM Account'){
      setForgotPasswordLink(true);
    }else{
      setForgotPasswordLink(false);
    }
  };

  useEffect(() => {
    const activeSession = localStorage.getItem('userInfo'); 
    if (activeSession) {
      navigate('/dashboard');
    }
    if (inProgress === InteractionStatus.None && authInProgress) {
      setAuthInProgress(false);
    }
    
  }, [inProgress,authInProgress,navigate]);

  const handleLogin = async () => {
    try {
        if (!validateFields()) {
          return;
        }       
      if (selectedOption === 'IOM Account') {
          if (!authInProgress) {
              setAuthInProgress(true);
              try {
                    const response=  await msalInstance.loginPopup(loginRequest);
                    if(response !== null){
                        const payload = {accountType : selectedOption, emailId : response.account.username, securityCode:''};
                        Signin(payload).then((result) => {
                        if(result !== 'Network Error'){
                          if (result.data.success) {   
                            localStorage.setItem("userInfo",JSON.stringify(result.data));   
                            loginsuccess();    
                            navigate('/dashboard');
                          }else{
                            showModal('failure',result.data.message);
                          }
                        }
                        else {
                          showModal('failure',result);
                        }         
                      }); 
                    }
              } catch (error) {
                showModal('failure', 'AD authentication failed with error '+ error);
              } 
          } else {
            showModal('failure','Authentication in progress');
          }
      } else {
        //Normal Login Process
          const hashedPassword = HashedPassword(securityCode);
          setSecurityCode(hashedPassword);
          const payload = {accountType : selectedOption, emailId : username, securityCode:hashedPassword};
          Signin(payload).then((result) => {
          if(result !== 'Network Error'){
            if (result.data.success) {   
              localStorage.setItem("userInfo",JSON.stringify(result.data));   
              loginsuccess();    
              navigate('/dashboard');
            } else {
              if(result.data.inValidAttemptCount >= config.lockAttemptCount){
                var payload = {accountType : selectedOption, emailId : username, securityCode:hashedPassword, invalidAttemptCount: result.data.inValidAttemptCount};
                LockAccount(payload).then((result) => {
                showModal('failure',result.data.message);
                });
              }else{
                showModal('failure',result.data.message);
              }
            }
          }
          else{
            showModal('failure',result);
          }         
        }); 
      }
    } catch (error) {
      showModal('failure','Error logging in:'+ error);
    }
  };

  return (
    <div className='login-wrapper'>
      <div className='row'>
        <div className="col-md-6 login-info">
          <div className="mb-5">
            <Link to='/' className="navbar-brand">
              <img src="/IOM-logo-white.png" alt="Logo" />
            </Link>
          </div>
          <h1>Welcome to IOM Surge Deployments</h1>
          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit,
            sed do eiusmod tempor incididunt ut labore et dolore
            magna aliqua. Ut enim ad minim veniam, quis nostrud
            exercitation ullamco laboris nisi ut aliquip ex ea
            commodo consequat.
          </p>
        </div>
        <div className="col-md-6 login-form">
          <h6 className="text-center">Please Sign in to your account</h6>
          <div className="card">
            <div className="card-body">
              <div className="form-group mb-3">
                <label htmlFor="authType" className="form-label">Select Account Type <span style={{color:"red", font:"bold"}}>*</span></label>
                <select id="authType" className="form-select" aria-label="Default select example" value={selectedOption} onChange={(e) => handleInputChange(e, setSelectedOption, 'selectedOption')}>
                  <option value="IOM Account">IOM Account</option>
                  <option value="Personal Account">Personal Account</option>
                </select>
                {errors.selectedOption && <p style={{color: 'red'}}>{errors.selectedOption}</p>}
              </div>
              {selectedOption === 'Personal Account' && (
                <>
                  <div className="form-group mb-3">
                    <label htmlFor="username">Username <span style={{color:"red", font:"bold"}}>*</span></label>
                    <input type="text" id="username" className="form-control" value={username} onChange={(e) => handleInputChange(e, setUsername, 'username')} />
                    {errors.username && <p style={{color: 'red'}}>{errors.username}</p>}
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="securityCode">Password <span style={{color:"red", font:"bold"}}>*</span></label>
                    <input type="password" id="securityCode" className="form-control" value={securityCode} onChange={(e) => handleInputChange(e, setSecurityCode, 'securityCode')} />
                    {errors.securityCode && <p style={{color: 'red'}}>{errors.securityCode}</p>}
                  </div>
                </>
              )}
              <button className="btn btn-primary w-100" onClick={handleLogin}>Login</button>
              <div className="mt-3 d-flex justify-content-between">
                <div>Don't have an account? <Link to="/signup"> Sign Up</Link></div>
                {forgotPasswordLink && (<div><Link to="/forgotPassword"> Forgot Password</Link></div>)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
