import React, { createContext, useState, useContext } from 'react';

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [modalState, setModalState] = useState({ show: false, type: '', message: '' });

  const showModal = (type, message) => {
    setModalState({ show: true,type, message });
  };

  const hideModal = () => {
    setModalState({ show: false,type: '', message: '' });
  };

  return (
    <ModalContext.Provider value={{ modalState, showModal, hideModal }}>
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  return useContext(ModalContext);
};
