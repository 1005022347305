import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import './index.css';  
import reportWebVitals from './reportWebVitals';
import App from './App';
import { ModalProvider } from './components/modalPopup/modelcontext';
import ModalPopUp from './components/modalPopup/modalpopup';
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from './services/ssoAuthConfig';

const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(
  document.getElementById('root')
)
root.render(
  <React.StrictMode> 
     <MsalProvider instance={msalInstance}>
  <ModalProvider>
    <App/> 
    <ModalPopUp/>
    </ModalProvider> 
    </MsalProvider>
  </React.StrictMode>
);
reportWebVitals();
