import React, { useState, useEffect } from 'react';
import Header from '../../assets/header/Header.js';
import Sidebar from '../../assets/sidebar/sidebar.js';
import { AddConfigSetting, GetAllConfigSettings } from '../../services/adminServices.js';
import { useModal } from '../modalPopup/modelcontext.js';

const ConfigSettings = () => {
    const [editingRow, setEditingRow] = useState(null); 
    const [editedValue, setEditedValue] = useState('');
    const { showModal } = useModal();
    const [allCsDetails, setAllCsDetails] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState('');

    const fetchConfigSettings = async() => {
        try{
            GetAllConfigSettings().then((response) =>{
                if(response.status === 200){
                    setAllCsDetails(response.data);
                }
            });
        }catch(error){
            console.error('Error while fetching config details:', error);
        }
    };

    useEffect(() => { 
        fetchConfigSettings();        
    },[]);
 
    // Function to calculate pagination and filter data
    const filteredConfigSettings = allCsDetails.filter((cs) =>(
        (cs.csCode && cs.csCode.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (cs.csDescription && cs.csDescription.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (cs.csValue && cs.csValue.toLowerCase().includes(searchQuery.toLowerCase())))
    );

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredConfigSettings.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredConfigSettings.length / itemsPerPage);
    const goToPage = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const startRecord = indexOfFirstItem + 1;
    const endRecord = Math.min(indexOfLastItem, filteredConfigSettings.length);
    //End Pagination logic here 

    const handleEditClick = (cs) => {
        setEditingRow(cs.csId); 
        setEditedValue(cs.csValue);
    };

    const handleCancelClick = () => {
        setEditingRow(null);
    };

    const saveConfigSettingData = async (cs) =>{
        try {
            if (!editedValue) {
                showModal('failure','Value is required.');
                return;
            }
            var payload = {csId: cs.csId, csValue: editedValue};
            AddConfigSetting(payload).then((res) => {
            if (res.data.success) {
                showModal('success',res.data.message);
                setEditingRow(null);
                fetchConfigSettings();
            } else {
                showModal('failure',res.data.message);
            }       
            });
        } 
        catch (error) {
            showModal('failure','Adding New Config Setting failed, Please try again later.');
        }
    };

    return (
        <>
            <Header />
            <div className="d-flex">
                <Sidebar />
                <div className="main bg-light-iom">
                    <div className='dashboard-class'>
                        <div className='cont-wrapper'>
                            <div className="container mt-5">
                                <div className="mb-3">
                                    <div className='row'>
                                        <div className="col-md-6">
                                            <h4>Configuration Settings</h4>
                                        </div>
                                    </div>
                                </div>                              
                                <div id="teamdetailsList" className='card mb-3'>
                                    <div className="card-header">
                                        <h6>Configuration Settings List</h6>
                                    </div>
                                        <div className='card-body'>
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                            <div>
                                                Show{' '}
                                                    <select value={itemsPerPage} onChange={(e) => setItemsPerPage(Number(e.target.value))} className="form-select d-inline-block w-auto">
                                                        <option value={5}>5</option>
                                                        <option value={10}>10</option>
                                                        <option value={20}>20</option>
                                                        <option value={50}>50</option>
                                                    </select>{' '}
                                                entries
                                            </div>
                                            <div className="w-25">
                                                <input type="text" className="form-control" placeholder="Search..." value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
                                            </div>
                                        </div>          
                                        <div className="table-responsive" style={{fontSize:"0.9rem"}}>
                                            <table className="table table-bordered table-striped">
                                                <thead className="thead-light" style={{background:"ghostwhite"}}>
                                                    <tr>
                                                        <th style={{background:"#7a9fd9", textAlign: 'center', verticalAlign: 'middle', width:'85%'}}>Configuration Description</th>
                                                        <th style={{background:"#7a9fd9", textAlign: 'center', verticalAlign: 'middle'}}>Value</th>
                                                        <th style={{background:"#7a9fd9", textAlign: 'center', verticalAlign: 'middle'}}>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {currentItems.map((cs) => (
                                                    <tr key={cs.csId}>
                                                        <td>{cs.csDescription}</td>
                                                        <td>{editingRow === cs.csId ? (
                                                            <input type="text" value={editedValue} onChange={(e) => setEditedValue(e.target.value)} className="form-control"/>
                                                            ) : ( cs.csValue )}
                                                        </td>
                                                        <td style={{textAlign: 'center'}}>
                                                        {editingRow === cs.csId ? (
                                                        <>
                                                        <div style={{ display: 'inline-flex', gap: '5px' }}>
                                                            <button className="btn" onClick={()=>saveConfigSettingData(cs)}><i class="bi bi-floppy" style={{color:'#4ccd0af0'}}></i></button>
                                                            <button className="btn" onClick={handleCancelClick}><i class="bi bi-x-circle" style={{color:'#ff0000f5'}}></i></button>
                                                        </div>
                                                        </>
                                                    ) : (
                                                        <button className="btn btn-default btn-sm" onClick={() => handleEditClick(cs)} >
                                                            <span className='bi bi-pencil' style={{ color: '#ffc107' }} />
                                                        </button>
                                                    )}
                                                        </td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <span>
                                                Showing {startRecord} to {endRecord} of {allCsDetails.length} records
                                            </span>
                                            <nav aria-label="Page navigation example">
                                                <ul className="pagination justify-content-end">
                                                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                        <button className="page-link" onClick={() => goToPage(currentPage - 1)}><span className='pi pi-perviousbi bi-skip-backward-fill'></span></button>
                                                    </li>
                                                    {Array.from({ length: totalPages }, (_, index) => (
                                                    <li key={index + 1} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                                                        <button className="page-link" onClick={() => goToPage(index + 1)}>{index + 1}</button>
                                                    </li>
                                                    ))}
                                                    <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                                        <button className="page-link" onClick={() => goToPage(currentPage + 1)}><span className='bi bi-skip-forward-fill'/></button>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default ConfigSettings;
