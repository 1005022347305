import React from 'react';
import { useModal } from './modelcontext';
import './modalpopup.css';

const ModalPopUp = () => {
  const { modalState, hideModal } = useModal();

  return (
    <div className={`modal fade ${modalState.show ? 'show d-block' : 'd-none'}`} tabIndex="-1" role="dialog">
      <div className="modal-dialog" role="document">
        <div className={`modal-content ${modalState.type === 'success' ? 'modal-success' : 'modal-failure'}`}>
          <div className="modal-header">
            <div className="icon-container">
              {modalState.type === 'success' ? (
                <span className="success-icon">&#10003;</span>
              ) : (
                <span className="failure-icon">&#10060;</span>
              )}
            </div>
          </div>
          <div className="modal-body">
            <h5 className="modal-title">
              {modalState.type === 'success' ? 'Success!' : 'Failed!'}
            </h5>
            <p>{modalState.message}</p>
          </div>
          <div className="modal-footer">
            <button className="ok-button" onClick={hideModal}>Ok</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalPopUp;
