import React, { useState } from 'react';
import { useModal } from '../modalPopup/modelcontext';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from '../../assets/header/Header.js';
import Sidebar from '../../assets/sidebar/sidebar.js';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function EmailTemplate() {
    const [htmlContent, setHtmlContent] = useState('');
    const { showModal } = useModal();
    const [errors, setErrors] = useState({});
    
    EmailTemplate.modules = {
        toolbar: [
          [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{ 'align': [] }],
          ['link', 'image', 'code-block'],
          ['clean']
        ],
    };
      
    EmailTemplate.formats = [
        'header', 'font', 'list', 'bullet',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'align', 'link', 'image', 'code-block'
    ];

    return (
        <>
            <Header />
            <div className="d-flex">
                <Sidebar />
                <div className="main bg-light-iom">
                    <div className='dashboard-class'>
                        <div className='cont-wrapper'>
                            <div className="container mt-5">
                                <div className="mb-3">
                                    <div className='row'>
                                        <div className="col-md-6">
                                            <h4>Email Template</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="card mb-3">
                                    <div className='card-body'>
                                        <div className='row'>
                                            <div className='form-group mb-3 col-md-4'>
                                                
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="form-group mb-3 col-md-12">
                                                <label htmlFor="htmlContent" className='form-label'>Content <span style={{color:"red", font:"bold"}}>*</span></label>
                                                <ReactQuill 
                                                    value={htmlContent} 
                                                    onChange={setHtmlContent} 
                                                    modules={EmailTemplate.modules} 
                                                    formats={EmailTemplate.formats} 
                                                    theme="snow" 
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EmailTemplate;
