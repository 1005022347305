import axios from 'axios';
import {config } from '../constants/constant';
import CryptoJS from 'crypto-js';
import axiosInstance from '../constants/globalaxiosinterceptor';
export const changePasswordApi = config.apiUrl + "/api/ForgotPassword";

export async function signUp(data) {
    return await axios.post(config.apiUrl + '/api/Account/signup', data, {headers: config.headers} ).catch((response) => {
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    });
  }

export async function Signin(data){
    return await axios.post(config.apiUrl + '/api/Account/login', data, {headers: config.headers} ).catch((response) => {
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    });
}

export function HashedPassword(password) {
    const secretKey = CryptoJS.enc.Utf8.parse(config.passwordSecretKey);
    const iv = CryptoJS.enc.Utf8.parse(config.ivstring);
    const hashedPassword = CryptoJS.HmacSHA256(password, secretKey + iv).toString(CryptoJS.enc.Hex);
    return hashedPassword.trim().toUpperCase();
};

export async function LockAccount(data){
    return await axios.post(config.apiUrl + '/api/Account/lockaccount', data, {headers: config.headers} ).catch((response) => {
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    });
};

export const getToken = () => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    return userInfo;
};

export const userRoles = () => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    return userInfo.roles;
}

export const refreshToken = async (data) => {
    try {
        return await axios.post(config.apiUrl + '/api/Account/refreshtoken', data, {headers: config.headers} ).catch((response) => {
            if (response.code === 200) {
                return (response.data);
            }
            else {
                return response.message;
            }
        });
    } catch (error) {
      // Handle error
      console.error("Failed to refresh token:", error);
      throw error;
    }
};

let forgotpasswordServiceConfig = {
    headers: {
        'Content-Type': 'application/json',
    }
}
export async function ForgotPassword(data, resetLink){
    forgotpasswordServiceConfig.headers.resetLink = resetLink;
    console.log(process.env.REACT_APP_API_URL);
    return await axios.post(process.env.REACT_APP_API_URL + '/api/ForgotPassword/forgotpassword',data, {headers: forgotpasswordServiceConfig.headers} ).catch((response) => {
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    });
};

export async function ResetPasswordService(data) {
    return await axios.post(process.env.REACT_APP_API_URL + '/api/ForgotPassword/resetPassword',data, {headers: forgotpasswordServiceConfig.headers} ).catch((response) => {
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    });
}

export async function UpdatePasswordService(data) {
    return await axiosInstance.post(changePasswordApi + '/changepassword', data, {headers: config.headers} ).catch((response) => {
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    });
}
