import React, { useState, useEffect } from 'react';
import Header from '../../assets/header/Header.js';
import Sidebar from '../../assets/sidebar/sidebar.js';
import { AddSbpPartnerDetails, GetAllSBPPartnersDetails } from '../../services/adminServices.js';
import { useModal } from '../modalPopup/modelcontext.js';

const SBPPartner = () => {
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [sbpName, setSbpName] = useState('');
    const [sbpDescription, setSbpDescription] = useState('');
    const [sbpEmails, setSbpEmails] = useState('');
    const [sbpStatus, setSbpStatus] = useState(true);
    const [errors, setErrors] = useState({});
    const { showModal } = useModal();
    const [allSbpPartnersDetails, setAllSbpPartnersDetails] = useState([]);
    const [sbpId, setSbpId] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState('');   
    const [btnSaveText, setBtnSaveText] = useState('');
    const [isEditClicked, setIsEditClicked] = useState(false);

    const handleAddSBPPartnerClick = () => {
        setIsFormVisible(true);
        setIsEditClicked(false);
        setBtnSaveText('Submit');
      };

    const handleCancelClick = () => {
        setIsFormVisible(false);
        setErrors({});
        setSbpName(null);
        setSbpDescription(null);
        setSbpEmails(null);
        setSbpStatus(null);
    };

    const fetchSBPPartnersDetails = async() => {
        try{
            GetAllSBPPartnersDetails().then((response) =>{
                if(response.status === 200){
                    setAllSbpPartnersDetails(response.data);
                }
            });
        }catch(error){
            console.error('Error while fetching wbs details:', error);
        }
    };

    useEffect(() => { 
        fetchSBPPartnersDetails();        
    },[]);
 
    // Function to calculate pagination and filter data
    const filteredSbpPartner = allSbpPartnersDetails.filter((sbp) =>(
        (sbp.sbpName && sbp.sbpName.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (sbp.sbpDescription && sbp.sbpDescription.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (sbp.sbpEmails && sbp.sbpEmails.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (sbp.sbpStatusName && sbp.sbpStatusName.toLowerCase().includes(searchQuery.toLowerCase())))
    );

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredSbpPartner.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredSbpPartner.length / itemsPerPage);
    const goToPage = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const startRecord = indexOfFirstItem + 1;
    const endRecord = Math.min(indexOfLastItem, filteredSbpPartner.length);
    //End Pagination logic here 

    const handleInputChange = (e, setState, fieldName) => {
        setState(e.target.value);
        if (fieldName === 'sbpName') {
            const isDuplicate = allSbpPartnersDetails.some(item =>
              item.sbpName === e.target.value
            );        
            if (isDuplicate) {
              setErrors((prevErrors) => ({
                ...prevErrors,
                [fieldName]: 'The SBP Name should be Unique',
              }));
            }
            else{
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [fieldName]: '',
                  }));
            }
        }else{
            setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: '',}));
        }        
      };

    const validate = () => {
        const newErrors = {};
        if (!sbpName) newErrors.sbpName = 'SBP Name is required.';
        if (!sbpDescription) newErrors.sbpDescription = 'SBP Description is required.';
        if (!sbpEmails) {
            newErrors.sbpEmails = 'SBP Emails are required.';
        } else {
            const validationError = validateEmails(sbpEmails);
            if (validationError) {
                newErrors.sbpEmails = validationError; 
            }
        }
        if(sbpName && sbpId===0){
            const isDuplicate = allSbpPartnersDetails.some(item =>
                item.sbpName === sbpName
            ); 
            if (isDuplicate) {newErrors.sbpName = 'SBP Name should be unique'; }
        }
        if(sbpName && sbpId!==0){
            const isDuplicate = allSbpPartnersDetails.some(item =>
                item.sbpName === sbpName && item.sbpId !== sbpId
            ); 
            if (isDuplicate) {newErrors.sbpName = 'SBP Name should be unique'; }
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const validateEmails = (emails) => {
        const emailArray = emails.split(",").map((email) => email.trim());
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
        for (let email of emailArray) {
          if (!emailRegex.test(email)) {
            return `Invalid EmailId: ${email}`;
          }
        }
        return null;
    };

    const clearControls=()=>{
        setSbpName('');
        setSbpDescription('');
        setSbpEmails('');
        setSbpStatus(false);
        setSbpId(0);
        setErrors({});
    }

    const saveSBPPartnerData = async () =>{
        try {
            if (!validate()) {
              return;
            }
            var payload = {sbpId, sbpName, sbpDescription, sbpEmails, sbpStatus};
            AddSbpPartnerDetails(payload).then((res) => {
            if (res.data.success) {
                showModal('success',res.data.message);
                setIsFormVisible(false);
                clearControls();
                fetchSBPPartnersDetails();
            } else {
                showModal('failure',res.data.message);
            }       
            });
        } 
        catch (error) {
            showModal('failure','Adding New SBP Partner failed, Please try again later.');
        }
    };

    const handleCheckboxChange = (e) => {
        setSbpStatus(e.target.checked);
    };

    const handleUpdateClick = (sbp) => {
        setIsFormVisible(true);
        setIsEditClicked(true);
        setBtnSaveText('Update');
        setSbpId(sbp.sbpId)
        setSbpName(sbp.sbpName);
        setSbpDescription(sbp.sbpDescription);
        setSbpEmails(sbp.sbpEmails);
        setSbpStatus(sbp.sbpStatus);
    };

    return (
        <>
            <Header />
            <div className="d-flex">
                <Sidebar />
                <div className="main bg-light-iom">
                    <div className='dashboard-class'>
                        <div className='cont-wrapper'>
                            <div className="container mt-5">
                                <div className="mb-3">
                                    <div className='row'>
                                        <div className="col-md-6">
                                            <h4>SBP Partner Agencies</h4>
                                        </div>
                                        <div className="col-md-6 text-md-right" style={{textAlign:'right'}}>
                                            <button className="btn btn-primary" onClick={handleAddSBPPartnerClick}>Add SBP Partner</button>
                                        </div>
                                    </div>
                                </div>
                                {isFormVisible && (
                                <div className="card mb-3">
                                    <div className="card-header">
                                    <h6>SBP Partner Information</h6>
                                    </div>
                                    <div className='card-body'>
                                        <div className='row'>
                                            <div className="form-group mb-3 col-md-6">
                                                <label htmlFor="sbpname" className="form-label">Name <span style={{color:"red", font:"bold"}}>*</span></label>
                                                <input type="text" className="form-control" id="sbpname" placeholder="SBP Partner Name" value={sbpName} onChange={(e) => handleInputChange(e, setSbpName, 'sbpName')}/>
                                                {errors.sbpName && <p style={{color: 'red'}}>{errors.sbpName}</p>}
                                            </div>    
                                            <div className="form-group mb-3 col-md-6">
                                                <label htmlFor="sbpdescription" className='form-label'>Description <span style={{color:"red", font:"bold"}}>*</span></label>
                                                <input type="text" className="form-control" id="sbpdescription" placeholder="SBP Partner Description" value={sbpDescription} onChange={(e) => handleInputChange(e, setSbpDescription, 'sbpDescription')}/>
                                                {errors.sbpDescription && <p style={{color: 'red'}}>{errors.sbpDescription}</p>}
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="form-group mb-3 col-md-12">
                                                <label htmlFor="sbpemail" className="form-label" title="Provide the SBP Partners Email in comma separated format">Emails <span style={{color:"red", font:"bold"}}>*</span></label>
                                                <input type="text" className="form-control" id="sbpemail" placeholder="Provide the SBP Partners Email in comma separated format" value={sbpEmails} onChange={(e) => handleInputChange(e, setSbpEmails, 'sbpEmails')}/>
                                                {errors.sbpEmails && <p style={{color: 'red'}}>{errors.sbpEmails}</p>}
                                            </div>  
                                        </div>
                                        <div className='row'>
                                            <div className="form-group col-md-1">
                                                <input id='sbpstatus' type='checkbox' className='me-1 mb-2' checked={sbpStatus} onChange={handleCheckboxChange}></input>
                                                <label htmlFor="sbpstatus" className='form-label'>Is Active </label>
                                                {errors.sbpStatus && <p style={{color: 'red'}}>{errors.sbpStatus}</p>}
                                            </div>
                                            <div className="form-group col-md-11" style={{textAlign:'right'}}>
                                                <button type="submit" id="btnSave" className="btn btn-primary me-2" onClick={saveSBPPartnerData}>{btnSaveText}</button> 
                                                {!isEditClicked && (
                                                    <button type="reset" className="btn btn-secondary me-2" onClick={clearControls}>Clear Form</button>
                                                )}                                                
                                                <button type="button" className="btn btn-danger" onClick={handleCancelClick}>Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                )}                              
                                <div id="teamdetailsList" className='card mb-3'>
                                    <div className="card-header">
                                        <h6>SBP Partner List</h6>
                                    </div>
                                        <div className='card-body'>
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                            <div>
                                                Show{' '}
                                                    <select value={itemsPerPage} onChange={(e) => setItemsPerPage(Number(e.target.value))} className="form-select d-inline-block w-auto">
                                                        <option value={5}>5</option>
                                                        <option value={10}>10</option>
                                                        <option value={20}>20</option>
                                                        <option value={50}>50</option>
                                                    </select>{' '}
                                                entries
                                            </div>
                                            <div className="w-25">
                                                <input type="text" className="form-control" placeholder="Search..." value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
                                            </div>
                                        </div>          
                                        <div className="table-responsive" style={{fontSize:"0.9rem"}}>
                                            <table className="table table-bordered table-striped">
                                                <thead className="thead-light" style={{background:"ghostwhite"}}>
                                                    <tr>
                                                        <th style={{background:"#7a9fd9", textAlign: 'center', verticalAlign: 'middle'}}>Name</th>
                                                        <th style={{background:"#7a9fd9", textAlign: 'center', verticalAlign: 'middle'}}>Description</th>
                                                        <th style={{background:"#7a9fd9", textAlign: 'center', verticalAlign: 'middle'}}>Emails</th>
                                                        <th style={{background:"#7a9fd9", textAlign: 'center', verticalAlign: 'middle'}}>Status</th>
                                                        <th style={{background:"#7a9fd9", textAlign: 'center', verticalAlign: 'middle'}}>Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {currentItems.map((sbp) => (
                                                    <tr key={sbp.sbpId}>
                                                        <td>{sbp.sbpName}</td>
                                                        <td>{sbp.sbpDescription}</td>
                                                        <td>{sbp.sbpEmails}</td>
                                                        <td>{sbp.sbpStatusName}</td>
                                                        <td style={{textAlign: 'center'}}>
                                                            <button className="btn btn-default btn-sm" onClick={() => handleUpdateClick(sbp)}>
                                                            <span className='bi bi-pencil' style={{color:'#ffc107'}}/>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <span>
                                                Showing {startRecord} to {endRecord} of {allSbpPartnersDetails.length} records
                                            </span>
                                            <nav aria-label="Page navigation example">
                                                <ul className="pagination justify-content-end">
                                                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                        <button className="page-link" onClick={() => goToPage(currentPage - 1)}><span className='pi pi-perviousbi bi-skip-backward-fill'></span></button>
                                                    </li>
                                                    {Array.from({ length: totalPages }, (_, index) => (
                                                    <li key={index + 1} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                                                        <button className="page-link" onClick={() => goToPage(index + 1)}>{index + 1}</button>
                                                    </li>
                                                    ))}
                                                    <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                                        <button className="page-link" onClick={() => goToPage(currentPage + 1)}><span className='bi bi-skip-forward-fill'/></button>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default SBPPartner;
